div.dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
}

div.dashboard_menu {
    height: 100%;
    width: 320px;
    border: 0px solid red;
}

div.dashboard_menu_ {
    width: 300px;
}

div.dashboard_contain {
    /* width: 100%; */
    flex: 1;
    border: 0px solid blue;
}

div.loginScreen {
    position: fixed;
    background-color: #5dc269;
    z-index: 1000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.loginScreen_title {
    text-align: center;
    padding-top: 40px;
    color: #ffffff;
    font-weight: bold;
    font-size: 2.5em;
    letter-spacing: 2px;
    text-shadow: 4px 4px 20px #ffffff;
}

.savoireFairee_videosList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.savoireFairee_videosList_elt {
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    margin-right: 20px;
    margin-bottom: 30px;
}

.savoireFairee_videosList_elt video {
    height: 200px;
}

.savoireFairee_videosList_elt_ {
    padding-left: 5px;
}

.savoireFairee_videosList_elt_title {
    font-weight: bold;
    color: #5dc269;
    word-break: break-word;
}

.savoireFairee_videosList_elt_desc {
    color: #000000aa;
    margin-top: 5px;
    word-break: break-word;
}