div.dashboardMenu {
    background-color: #6a916c;
    height: 100%;
    width: 300px;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 0;
}

div.dashboardMenu::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #5dc26900;
    border: 0px solid #5dc26955;
    border-radius: 0px;
    position: fixed;
    left: 0;
}

div.dashboardMenu::-webkit-scrollbar-track {
    width: 0px;
    background-color: #5dc26900;
    border: 0px solid transparent;
}

div.dashboardMenu::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 30px;
    background-color: #5dc269;
    border: 0px solid transparent;
}

.dashboardMenu_icon {
    /* flex: 1; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    border: 0px solid blue;
}

.dashboardMenu_icon_ {
    background-image: url('../../assets/logos/rad-logo.png');
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    width: 65px;
    height: 65px;
    border: 0px solid #ffffff;
    border-radius: 100%;
    transform: scale(0);
    animation: scale_anim3 1s forwards;
    animation-delay: 0ms;
}

.dashboardMenu_icon_text {
    margin-left: 5px;
    letter-spacing: 0px;
}

.dashboardMenu_icon_text_name {
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2em;
}

.dashboardMenu_icon_text_slug {
    color: #ffffff;
    font-size: 0.7em;
    margin-top: 5px;
}

div.dashboardMenu_itemContainer {
    padding: 20px;
    padding-top: 30px;
    padding-left: 30px;
}

div.dashboardMenu_item {
    color: #ffffff;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1em;
    display: flex;
    align-items: center;
    transform: translate3d(0, 0, 0);
}

.dashboard_link_menu {
    margin-bottom: 20px;
    padding-bottom: 15px;
    display: block;
    border: 0px solid red;
}

div.dashboardMenu_item_line {
    height: 2px;
    margin-top: 15px;
    background-color: #ffffff;
    width: 0px;
}

.dashboard_link_menu:hover .dashboardMenu_item_line {
    width: 100% !important;
}

div.dashboardMenu_item svg {
    margin-left: 10px;
    font-size: 16px !important;
    width: 20px !important;
    height: 20px !important;
    border: 0px solid red;
    transform: translate3d(0, 0, 0);
}