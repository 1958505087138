div.dashboardRubrique_home_title {
    letter-spacing: 2px;
    margin-top: 20px;
    font-size: 1.5em;
    padding-left: 20px;
}

div.dashboardRubrique_home_head {
    text-align: center;
    margin-top: 30px;
    font-size: 2em;
    letter-spacing: 2px;
    text-decoration: underline;
}

div.porteffolio {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

div.porteffolio_ {
    max-width: 300px;
    margin-bottom: 30px;
    margin-right: 20px;
    transform: translate3d(0, 0, 0);
    border: 0px solid red;
    height: 100%;
}

img.porteffolio_image {
    width: 100%;
    height: 100%;
    height: 250px;
    border-radius: 5px;
    object-fit: cover;
}

div.porteffolio_text {
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    border: 0px solid red;
    height: 100%;
    max-height: 250px;
    width: 100%;
}

div.porteffolio_text div {
    display: inline-block;
    background-color: #000000aa;
    color: #ffffff;
    font-weight: bold;
    margin-top: 10px;
    padding: 15px 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

div.porteffolio_bottom {
    margin-top: 20px;
    display: flex;
    font-size: 0.9em;
}

div.porteffolio_bottom_input {
    width: 100%;
    margin-top: 20px;
    position: absolute;
    z-index: -1000;
    opacity: 0;
    transform: scaleY(0);
    display: flex;
}

input.porteffolio_bottom_input_ {
    width: 100%;
    border: 1px solid #00000055;
    padding: 10px 20px;
    border-radius: 5px;
    outline: none;
}

.add_content_area {
    margin-top: 40px;
    width: 100%;
    height: 300px;
    padding: 20px;
    resize: none;
    border: 1px solid #00000055;
    border-radius: 5px;
    font-size: 1.2em;
    letter-spacing: 1px;
    line-height: 1.7em;
    font-family: 'Lato-Regular';
    text-align: justify;
    outline: none;
}

.add_content_area_button {
    margin-top: 20px;
}

.add_content_editor * {
    line-height: 1.7em;
    word-wrap: break-word;
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    letter-spacing: 0px;
    white-space: wrap;
}

.textContent {
    margin-bottom: 30px;
}

.textContent_text {
    margin-top: 30px;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 1.3em;
    font-weight: bold;
    color: #5dc269;
}

.textContent_inputForModify {
    max-width: 300px;
    margin-left: 10px;
}

.partnaireSlug {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 1.7em;
}

.partnaireLogo {
    margin-top: 0px;
    object-fit: contain;
    max-width: 100px;
}

.partnaire {
    border: 1px solid #00000055;
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
}

.partnaireDescription {
    margin-top: 30px;
    margin-bottom: 10px;
    line-height: 1.7em;
    color: #000000aa;
}

.partnaireLink {
    color: blue;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 10px;
}

.radSections {
    padding: 0px 20px;
}

.radSections_title {
    display: flex;
    align-items: center;
}

.radSections_title_ {
    padding-left: 0px;
    font-size: 1.3em;
    font-weight: bold;
    color: #5dc269;
}

.radSection {
    margin-top: 40px;
    border-bottom: 1px solid #00000055;
    padding-bottom: 60px;
}

.radSection_name {
    font-weight: bold;
    color: #5dc269;
    letter-spacing: 1px;
}

.radSection_editIcon {
    font-weight: bold;
    color: #00000055;
    font-size: 1.4em;
}

.radSection_editText {
    margin-top: 10px;
}

.update_sectionText {
    margin-left: -10px;
}

.update_sectionText span {}

.addSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000055;
    overflow-y: auto;
    z-index: 100 !important;
}

.addSection_ {
    margin: auto;
    border: 0px solid red;
    width: 100%;
    max-width: 600px;
    margin-top: 0px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
}

.addSection_title {
    text-align: center;
    color: #5dc269;
    font-size: 1.1em;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.addSection_input {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.addSection_input span {
    margin-bottom: 5px;
}

.addSection_input input {
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #00000055;
}

.addSection_text_editor {
    margin-top: 5px;
}

.deleteSection {
    margin-top: 20px;
}

.deleteSection span {
    padding: 10px 30px;
    color: #ffffff;
    background-color: red;
    font-weight: bold;
    font-size: 0.9em;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
}

.update_pen {
    padding: 15px;
    color: #5dc269;
    font-size: 1.3em;
    cursor: pointer;
}

.currentLogoContainer {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
}

.choice_image {
    border: 1px solid #00000000 !important;
    background-color: transparent;
}

.uploadProgressPlatform {
    max-width: 300px !important;
    margin-top: 60px;
}


/* class="ck ck-reset ck-editor ck-rounded-corners" */


/* .add_content_editor-2 .ck-editor {
    max-height: 100px;
} */

.add_content_editor-2 .ck-content {
    height: 100px !important;
}

.add_content_editor-2 div.add_content_editor {
    min-height: 100px;
    margin-top: 10px;
}

.add_content_editor-2 div.ck-editor__main {
    min-height: 100px !important;
}

.add_content_editor-2 div.ck-content {
    min-height: 100px !important;
}

.add_content_editor-3 div.add_content_editor {
    min-height: 300px;
    margin-top: 10px;
}

.add_content_editor-3 div.ck-editor__main {
    min-height: 300px !important;
}

.add_content_editor-3 div.ck-content {
    min-height: 300px !important;
}


/* ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline */