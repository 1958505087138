.Slider1_container {
    width: 100%;
    margin: auto;
    border: 0px solid red;
    display: flex;
    transform: translate3d(0, 0, 0);
    /* padding: 0px 20px; */
    font-family: Open Sans, serif;
}

.Slider1_container_head {
    width: 100%;
    padding-right: 20px;
    border: 0px solid red;
    position: relative;
    z-index: 1;
}

.Slider1_container_body {
    padding-left: 20px;
    position: relative;
    z-index: 1;
    /* border: 1px solid red; */
    transform: translate3d(0, 0, 0);
}

.Slider1_container_head_elt {
    border: 1px solid #5dc269;
    margin-bottom: 10px;
    padding: 15px 10px;
    border-radius: 5px;
    letter-spacing: 0.5px;
    cursor: pointer;
    transform-origin: 0 center;
    width: 100%;
    transform: translate3d(0, 0, 0);
    white-space: nowrap;
}

.Slider1_container_head_elt:hover {
    background-color: #5dc269 !important;
    color: #ffffff !important;
    font-weight: bold !important;
    /* border-radius: 0px; */
    /* border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; */
}

.Slider1_container_head_elt_name {
    display: inline-block;
    font-family: Open Sans, serif;
}

.Slider1_container_head_elt_line {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0px;
    width: 100%;
    transform: scaleX(0);
    height: 0px;
    background-color: #ffffff;
    transition-duration: 500ms;
}

.Slider1_container_head_elt:hover .Slider1_container_head_elt_line {
    transform: scaleX(1) !important;
    height: 3px !important;
}

.Slider1_container_body_title {
    text-align: right;
}

.Slider1_container_body_title span {
    font-size: 0.9em;
    font-weight: 600;
    color: #5dc269;
    border-bottom: 1px solid #5dc269;
}

.Slider1_container_body_content {
    text-align: justify;
    transform: translate3d(0, 0, 0);
    padding-bottom: 60px;
    /* background-color: #5dc26955; */
}

.Slider1_container_body_rect {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #5dc269;
    border-radius: 2px;
}

@media (max-width: 680px) {
    .Slider1_container {
        flex-direction: column;
    }
    .Slider1_container_head {
        padding-right: 0px;
    }
    .Slider1_container_body {
        padding-left: 0px;
        margin-top: 30px;
    }
    .Slider1_container_body_title {
        text-align: left;
    }
    .Slider1_container_body_title span {
        font-size: 1.2em;
        font-weight: 800;
        text-align: left;
    }
}