div.media {
    overflow: hidden;
    min-height: 500px;
}

div.media_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}

div.media_folder {
    width: 100%;
    /* min-width: 200px; */
    max-width: 250px;
    /* margin: auto; */
    padding: 20px;
    border: 0px solid red;
}

div.media_folder_ {
    box-shadow: 10px 10px 10px 5px #00000055;
    border-radius: 2px;
    cursor: pointer;
}

img.media_folder_cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

div.media_folder_name {
    padding: 20px;
}

div.media_folder_name span {
    font-weight: bold;
    letter-spacing: 2px;
    color: #5dc269;
    word-break: break-word;
}

div.media_folder_container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
}

div.media_folder_container_name {
    width: 100%;
    border: 0px solid red;
    margin-top: 30px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    color: #5dc269;
    font-weight: bold;
    word-break: break-word;
}

div.media_content_imageContainer {
    padding: 10px;
    /* margin: 0px auto; */
    margin-bottom: 0px;
    min-width: 200px;
}

div.media_content_image {
    width: 100%;
    min-height: 100%;
    /* margin: auto; */
    padding: 0px;
    display: block;
    border: 0px solid red;
    box-shadow: 10px 10px 10px 5px #00000055;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ffffff;
    max-width: 200px;
    display: flex;
    flex-direction: column;
}

img.media_content_image_img {
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    /* object-position: center center; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

div.media_content_image_name {
    width: 100%;
    display: block;
    background-color: #ffffff00;
    word-break: break-word;
    padding: 20px 10px;
    color: #00000099;
    font-size: 0.8em;
}

div.back {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

div.back_ {
    background-color: #5dc269;
    color: #ffffff;
    position: relative;
    right: 0;
    padding: 12px 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
}

div.back span {}

div.currentImageContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000aa;
    z-index: 1000000000000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-top: 0px;
}

div.currentImage {
    width: 100%;
    height: 100%;
    text-align: center;
}

img.currentImage_ {
    max-width: 100%;
    max-height: 100vh;
    object-fit: contain;
}

div.crossImage {
    position: absolute;
    display: inline-flex;
    right: 0;
    transform: scale(1.5);
    margin-top: 20px;
    margin-right: 20px;
    background-color: #000000aa;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

div.media_folder_container_current {
    text-align: center;
    font-size: 2em;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #00000055;
    letter-spacing: 1px;
}