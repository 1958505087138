div.card3 {
    height: 100%;
    flex: 1;
    background-color: #ffffff;
    box-shadow: 10px 10px 10px 3px #00000011;
}

div.card3_title {
    padding: 40px 30px;
    text-align: center;
    color: #ffffff;
    font-size: 1.3em;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    line-height: 1.6em;
}

div.card3_content {
    padding: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    line-height: 1.6em;
    color: #00000099;
    font-size: 1.1em;
}