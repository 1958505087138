div.OnActualityView {
    overflow: hidden;
    max-width: 1000px;
    margin: auto;
    border: 0px solid red;
    padding-top: 10px;
}

div.OnActualityView_cover {
    width: 100%;
    text-align: center;
    max-height: 400px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

div.OnActualityView_cover::after {
    background-color: #f7f7f7;
    bottom: 0;
    box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.07);
    content: "";
    height: 30%;
    position: absolute;
    top: 95%;
    -webkit-transform-origin: 10% 0;
    transform-origin: 10% 0;
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
    width: 110%;
    left: 0;
    margin-left: -5px;
    z-index: 0;
}

img.OnActualityView_cover_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    max-height: 400px;
    display: block;
    vertical-align: middle;
}

div.OnActualityView_title {
    text-align: center;
    padding: 0px 20px;
    color: #5dc269;
    font-size: 1.5em;
    line-height: 1.5em;
    max-width: 800px;
    margin: auto;
    font-weight: 100;
    margin-top: 20px;
    font-family: Lato-Black;
    letter-spacing: 1px;
}

div.OnActualityView_date {
    text-align: center;
    font-size: 0.8em;
    color: #00000099;
    margin-top: 10px;
    font-weight: 600;
    letter-spacing: 1px;
}

div.OnActualityView_competence_title {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2em;
    letter-spacing: 1px;
}

div.OnActualityView_top_competence {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5px;
    padding: 0px 10px;
}

.OnActualityView_top_competence_ {
    margin: 10px 2px;
    border: 0px solid red;
}

.OnActualityView_top_competence_ span {
    background-color: #5dc269;
    padding: 10px 15px;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.7em;
    border-radius: 3px;
}

div.OnActualityView_share {
    margin-top: 40px;
    padding: 0px 10px;
}

div.OnActualityView_share_text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

span.OnActualityView_share_text_ {
    margin-right: 10px;
    padding-top: 10px;
}

div.OnActualityView_share_button span {
    margin-right: 5px;
    padding-top: 10px;
    display: inline-block;
}

div.OnActualityView_body {
    /* border-top: 1px solid #5dc269; */
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
}

div.OnActualityView_body_text {
    margin-top: 20px;
    padding: 0px 10px;
    letter-spacing: 3px;
    color: #00000099;
}

#element_to_copy {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform: scale(0);
    left: -1000000;
    top: -1000000000;
}