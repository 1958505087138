.Testimony_container {
    background: #fafbfd;
    border-radius: 8px;
    width: 100%;
    margin-left: 0%;
    padding: 5px 0 0 5px;
    vertical-align: baseline;
    display: flex;
    padding: 20px;
    align-items: center;
    font-family: Open Sans, serif;
}

.Testimony_container * {
    vertical-align: baseline;
}

.Testimony_container_left {
    display: inline-block;
    border-radius: 7px;
    transform: translate3d(0, 0, 0);
    width: 100%;
    max-width: 350px;
}

.Testimony_container_left_content::before {
    content: '';
    height: 100%;
    width: 100%;
    background: #cccccc55;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 7px;
    transform: translateX(-34px);
}

.Testimony_container_left_content {
    position: relative;
    z-index: 2;
}

.Testimony_container_left_content_ {
    max-height: 460px;
    overflow: auto;
    padding: 20px;
    margin-left: -20px;
    /* border: 1px solid red; */
}

.Testimony_container_left_content_::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #5dc26900;
    border: 0px solid #5dc26911;
    border-radius: 0px;
    position: fixed;
    left: 0;
}

.Testimony_container_left_content_::-webkit-scrollbar-track {
    width: 0px;
    background-color: #5dc26900;
    border: 0px solid transparent;
}

.Testimony_container_left_content_::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 30px;
    background-color: #5dc26955;
    border: 0px solid transparent;
}

.Testimony_container_left_content_elt {
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 10px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 9px 0 #00000055;
    border-bottom: 3px solid #5dc269;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
}

.Testimony_container_left_content_elt:hover {
    background-color: #5dc269 !important;
    transform: translateX(5px) !important;
    color: #ffffff !important;
    box-shadow: 5px 5px 9px 3px #00000055 !important;
}

.Testimony_container_left_content_elt:hover .Testimony_container_left_content_elt_text_content {
    color: #d7f2da !important;
    font-weight: 600 !important;
    text-align: right !important;
}

.Testimony_container_left_content_elt:hover .Testimony_container_left_content_elt_text_title {
    font-weight: 700 !important;
    display: block !important;
    text-align: right !important;
    color: #fff !important;
}

.Testimony_container_left_content_elt_logo {
    width: 50px;
    height: 50px;
    min-width: 50px;
    background-color: #fff;
    border-radius: 50px;
    margin-right: 10px;
    box-shadow: 0 5px 9px 0 rgba(31, 105, 236, .2);
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Testimony_container_left_content_elt_logo img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50px;
}

.Testimony_container_left_content_elt_logo svg {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50px;
    color: #aaaaaa;
}

.Testimony_container_left_content_elt_text {
    width: 100%;
}

.Testimony_container_left_content_elt_text_title {
    letter-spacing: 0px;
    font-weight: 600;
    font-size: 0.9em;
}

.Testimony_container_left_content_elt_text_content {
    color: #000000aa;
    font-size: 0.8em;
    margin-top: 5px;
    letter-spacing: 0px;
    width: 100%;
    /* border: 1px solid red; */
}

.Testimony_container_right {
    border: 0px solid blue;
    width: 100%;
    margin-left: 20px;
}

.Testimony_container_left_content_elt_back {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.Testimony_container_left_content_elt_back img {
    object-fit: contain;
    width: 80px;
    margin-left: -5px;
}

.Testimony_container_menu {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2.5em;
    color: #5dc269;
    margin-left: 10px;
    z-index: -100;
    transform: scale(0);
    opacity: 0;
}

.Testimony_container_left_content_cross {
    font-size: 2.5em;
    color: #5dc269;
    margin-left: 10px;
    z-index: -100;
    transform: scale(0);
    opacity: 0;
    position: absolute;
}