.dashboardODD {
    width: 100%;
    height: auto;
    border: 1px solid #00000055;
    margin-right: 20px;
    padding: 30px;
    margin-top: 20px;
}

.dashboardODD_image {
    width: 100%;
    max-width: 100px;
    height: 100%;
    max-height: 100px;
}

.radODD {
    display: flex;
    align-items: center;
    color: #00000055;
    font-weight: bold;
    letter-spacing: 1px;
}

.radODD_title {
    margin-top: 10px;
    text-decoration: underline;
}

.radODD_editor {
    margin-top: 15px !important;
}