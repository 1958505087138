div.OnCompetenceView {
    min-height: 1000px;
    padding: 0px 20px;
}

div.OnCompetenceView_top_title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border: 0px solid red;
    text-align: center;
    margin-top: 10px;
    padding: 0px 20px;
}

div.OnCompetenceView_top_subtext {
    text-align: center;
    margin-top: 40px;
}

div.OnCompetenceView_top_title span {
    /* background-color: #5dc269; */
    color: #5dc269;
    padding: 10px 0px;
    font-size: 2em;
    font-family: Lato-Black;
    border-bottom: 0px solid #5dc269;
}

div.OnCompetenceView_body {
    max-width: 1200px;
    margin: auto;
    margin-top: 40px;
}

div.OnCompetenceView_body_ {
    margin-top: 80px;
}