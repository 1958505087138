@media (max-width: 950px) {
    span.OnProjectsView_top_text_ {
        font-size: 2.4em;
    }
}

@media (max-width: 733px) {
    div.OnProjectsView_body_actuality_card {
        width: 50%;
        padding: 10px 10px;
    }
    div.OnProjectsView_body_actuality_ {
        padding: 10px 20px;
    }
}

@media (max-width: 530px) {
    div.OnProjectsView_body_actuality_card {
        width: 100%;
        padding: 10px 0px;
        max-width: 300px;
        margin: auto;
    }
    div.OnProjectsView_body_actuality_ {
        padding: 10px 20px;
    }
}

@media (max-width: 510px) {
    span.OnProjectsView_top_text_ {
        font-size: 2em;
    }
    div.OnProjectsView_progress_text {
        font-size: 0.9em;
    }
}