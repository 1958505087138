@media (max-width: 500px) {
    div.userCard:hover div.userCard_body_title {
        /* transform: translate3d(0, 0%, 0); */
        color: #ffffff;
    }
    div.userCard:hover div.userCard_body_subtitle {
        transition-delay: 100ms;
        /* transform: translate3d(0, 0%, 0); */
        color: #ffffff;
    }
    div.userCard_body_title {
        font-size: 1em;
    }
    div.userCard_body_subtitle {
        font-size: 0.9em;
    }
}