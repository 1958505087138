@media ( max-width: 1200px) {
    span.donate_section2_logo_title {
        font-size: 4em;
    }
    span.donate_section2_logo_subTitle {
        font-size: 1.2em;
        text-align: center;
    }
    div.donate_section2_card_title {
        font-size: 1.3em;
    }
    div.donate_section3_title {
        font-size: 1.5em;
    }
}

@media ( max-width: 855px) {
    div.donate_section2 {
        flex-direction: column;
    }
    div.donate_section2_card_container {
        margin-top: 40px;
    }
}

@media ( max-width: 890px) {
    div.donate_section3_cardContainer {
        flex-wrap: wrap;
    }
    div.donate_section3_card {
        width: 50%;
        margin-bottom: 40px;
    }
}

@media ( max-width: 712px) {
    div.donate_section1_title {
        font-size: 2.5em;
    }
    div.donate_section1_subtitle {
        font-size: 1.1em;
    }
    div.donate_section3_card {
        width: 100%;
        margin-bottom: 40px;
    }
}

@media ( max-width: 600px) {
    div.donate_section1_title {
        font-size: 2em;
    }
    div.donate_section1_subtitle {
        font-size: 0.9em;
    }
    span.donate_section2_logo_title {
        font-size: 3em;
    }
    span.donate_section2_logo_subTitle {
        font-size: 1em;
    }
    div.donate_section2_card_title {
        font-size: 1.1em;
    }
    div.donate_section3_title {
        font-size: 1.2em;
    }
    div.donate_section3 {
        padding: 0px 20px;
    }
}

@media ( max-width: 495px) {
    div.donate_section1_title {
        font-size: 1.5em;
    }
}

@media ( max-width: 333px) {
    span.donate_section2_logo_title {
        font-size: 2em;
    }
    span.donate_section2_logo_subTitle {
        font-size: 0.8em;
    }
    div.donate_section2_card {
        margin-top: 0px;
    }
    div.donate_section2_card_button span {
        font-size: 0.8em;
        padding: 15px 30px;
    }
    div.donate_section3_title {
        font-size: 1em;
    }
}