@media( min-width: 1050px) {
    .Testimony_container_left {
        position: relative !important;
        transform: translateX(0%) !important;
    }
}

@media( max-width: 1050px) {
    .Testimony_container_left {
        position: absolute;
        z-index: 10;
        background-color: #ffffff;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        box-shadow: 5px 5px 5px 0px #00000055;
    }
    .Testimony_container_menu {
        z-index: 10;
        transform: scale(1);
        opacity: 1;
        cursor: pointer;
    }
    .Testimony_container_left_content_cross {
        z-index: 100;
        transform: scale(1);
        opacity: 1;
        position: relative;
    }
}