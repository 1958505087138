div.VideoSlider_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 0px solid red;
    overflow-x: hidden;
    background-color: #25352600;
    transform: translate3d(0, 0, 0);
}

div.VideoSlider_container_ {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
}

div.VideoSlider_container_div {
    width: 100%;
    border: 0px solid red;
    display: inline-block;
}

div.VideoSlider_container_divTextctn {
    word-break: break-word;
    word-wrap: break-word;
    white-space: inherit;
    transform: translate3d(0, 0, 0);
    display: flex;
    flex-direction: column;
}

span.VideoSlider_container_divTextctn_title {
    background-color: #5dc26988;
    font-size: 0.6em;
    color: #ffffff;
    margin-left: 10px;
    display: block;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 20px;
    font-weight: bold;
    letter-spacing: 2px;
    word-break: break-word;
    max-width: 100%;
    overflow-wrap: break-word;
    white-space: wrap;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

div.VideoSlider_container_divText {
    position: absolute;
    top: 0px;
    border: 0px solid red;
    width: 100%;
    max-width: 100%;
    background-color: #00000000;
    overflow-y: auto;
    overflow-x: hidden;
    word-break: break-word;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    padding-right: 20px;
    display: flex;
    align-items: flex-start;
    max-width: 200px;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.VideoSlider_container_divText_ {
    color: #ffffff;
    background-color: #ffffff88;
    display: inline-block;
    padding: 5px 10px;
    margin-top: 0px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-weight: normal;
    letter-spacing: 1px;
    word-break: break-word;
    width: 100%;
    overflow-wrap: break-word;
    white-space: wrap;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-bottom: 0px;
    margin-left: 10px;
    box-shadow: 5px 5px 15px 3px #00000055;
}

.VideoSlider_container_divText_span {
    display: inline-block !important;
    word-break: break-word !important;
    max-width: 100% !important;
    border: 0px solid red;
    overflow: hidden;
    width: 100%;
    height: auto;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    color: #000000;
    font-size: 0.6em;
}

div.VideoSlider_container_videos {
    white-space: nowrap;
    border: 0px solid red;
    /* display: flex; */
}

div.VideoSlider_container_videos_ {
    display: inline;
    width: 100%;
    transition-duration: 700ms;
}

img.VideoSlider_container_videos_img {
    width: 100%;
    height: 400px;
    object-fit: contain;
    image-rendering: optimizeQuality;
    object-position: top center;
}


/* .VideoSlider_container_::before {
    background-color: #00000000;
    content: "";
    position: relative;
    width: 100%;
    height: 400px;
    margin-bottom: -400px;
    display: block;
    left: 0;
} */

.VideoSlider_container_controller {
    height: 60px;
    overflow-x: auto;
    margin-top: 0px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    background-color: #ffffff00;
    z-index: 10;
}

.VideoSlider_container_controller::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #5dc26900;
    border: 0px solid #5dc26955;
    border-radius: 0px;
    position: fixed;
    left: 0;
}

.VideoSlider_container_controller::-webkit-scrollbar-track {
    width: 0px;
    background-color: #5dc26900;
    border: 0px solid transparent;
}

.VideoSlider_container_controller::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 30px;
    background-color: #5dc269;
    border: 0px solid transparent;
}

.VideoSlider_container_controller_ {
    border: 0px solid red;
    display: inline-flex;
    margin: 0px auto;
    white-space: nowrap;
}

.dot2 {
    width: 30px;
    height: 30px;
    font-weight: bold;
    font-size: 0.8em;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px solid #5dc26900;
    margin: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 5px 5px 5px 1px #00000055;
}

.dot:hover {
    transform: scale(0.8) !important;
    color: #ffffff !important;
    background-color: #5dc269 !important;
}

.dot_ {
    width: 11px;
    height: 11px;
    background-color: #47854e;
    border-radius: 100%;
}

div.VideoSlider_container_videos_text {}