div.dashboardHome_title {
    letter-spacing: 1px;
    font-size: 1.4em;
    margin-top: 20px;
    line-height: 1.7em;
}

div.dashboardHome_contentCard {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

div.dashboardHome_content_card {
    width: 22.33%;
    min-width: 200px;
    padding: 0px;
    max-width: 250px;
    transform: translate3d(0, 0, 0);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 40px;
}

div.dashboardHome_content_card_ {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 10px 10px 20px 5px #00000011;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

div.dashboardHome_content_card_:hover {
    transform-origin: center 0;
    animation: animeCardRotate 500ms forwards;
}

div.dashboardHome_content_card_image {
    width: 70px;
    height: 70px;
    margin-top: -50px;
}

div.dashboardHome_content_card_image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

div.dashboardHome_content_card_title {
    text-align: center;
    margin-top: 0px;
    letter-spacing: 1px;
    font-size: 1em;
    width: 100%;
    background-color: #5dc269;
    color: #ffffff !important;
    padding: 10px;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    z-index: 1;
}

div.dashboardHome_content_card_number {
    font-size: 2em;
    font-weight: bold;
    color: #5dc269;
    margin-top: 20px;
    letter-spacing: 1px;
}

div.cardCircle {
    background-color: transparent;
    position: absolute;
    margin-top: -18px;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 0;
}

div.cardCircle_ {
    border: 4px solid #5dc269;
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

div.cardCircle_p {
    width: 5px;
    height: 5px;
    background-color: #000000aa;
    border-radius: 100%;
    margin-top: -4px;
    margin: auto;
}

div.dashboardHome_contentChart {
    margin-top: 40px;
}