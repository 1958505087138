.homeView {
    opacity: 0;
    animation: opacity_anim 1000ms forwards;
    animation-delay: 1s;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
}

.shortDescriptionOfRad {
    display: flex;
    flex-direction: column-reverse;
    overflow-x: hidden;
    padding: 20px;
    max-width: 900px;
    margin: 0px auto;
}

.shortDescriptionOfRad_left {
    flex: 1;
    width: 100%;
    border: 0px solid #000000;
    margin-top: 50px;
}

.card {
    padding: 20px;
    width: 100%;
    background-color: #ffffff;
    /* box-shadow: 5px 5px 5px 2.5px #00000022; */
    min-height: 100px;
    border-radius: 5px;
    display: flex;
    margin-bottom: 40px;
    border-left: 1px solid #5dc269;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.card_left {
    padding: 0px 20px 0px 0px;
}

.cardIcon {
    font-size: 2em !important;
    color: #5dc269;
}

.card_right {
    line-height: 1.6em;
}

.card_title i {
    margin-right: 10px;
}

.card_title span {
    margin-top: 0px;
    display: block !important;
}

.card_text {
    margin-top: 10px;
    font-size: 1em;
    text-align: justify !important;
    color: #00000088;
    padding-right: 0px;
    word-break: break-word;
    max-width: 100%;
    overflow-wrap: break-word;
    white-space: wrap;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.card_text p {
    margin-top: 10px;
}

.card_text ul {
    margin-top: 10px;
    margin-left: 30px;
    list-style: disc;
}

.card_text strong {
    font-weight: normal;
}

.shortDescriptionOfRad_right {
    flex: 1;
    width: 100%;
    text-align: center;
    border: 0px solid #000000;
    padding: 20px 0px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.shortDescriptionOfRad_right_text {
    font-size: 1.2em;
    padding: 20px;
    color: #000000aa;
    font-weight: 500;
    text-align: justify !important;
    line-height: 1.7em !important;
}

.shortDescriptionOfRad_right_subTitle {
    font-size: 1.9em;
    margin-top: 0px;
    margin-bottom: 20px;
}

.shortDescriptionOfRad_right_logo {
    border: 0px solid red;
    padding: 20px;
}

.shortDescriptionOfRad_right_logo_ {
    max-width: 200px;
    object-fit: contain;
    border-radius: 100%;
}

div.shortDescriptionOfRad_right_domaine {
    margin-top: 20px;
    width: 100%;
    padding: 0px;
    box-sizing: border-box !important;
    text-align: center;
    background-color: #25352600;
    max-width: 900px;
    margin: auto;
    padding-left: 0px;
    box-sizing: border-box;
    border: 0px solid red;
}

.shortDescriptionOfRad_right_domaine_title {
    font-weight: bold;
    font-size: 1.9em;
    margin-bottom: 60px;
    text-align: left;
    padding-left: 20px;
    margin-top: 30px;
}

.shortDescriptionOfRad_right_domaine_ {
    border: 0px solid red;
    padding: 3px 10px;
    margin: 0;
}

div.shortDescriptionOfRad_right_domaine_one {
    color: #ffffff;
    background-color: #5dc269;
    display: inline-block;
    padding: 15px 20px;
    font-weight: bold;
    border-radius: 5px;
    font-size: 1.2em;
    margin: auto;
}

div.rad_partnaires {
    max-width: 900px;
    margin: auto;
    margin-top: 120px;
    position: relative;
    z-index: 1000;
}

div.rad_partnaires_title {
    padding-left: 20px;
}

div.rad_number {
    max-width: 900px;
    margin: auto;
    margin-top: 120px;
    position: relative;
    z-index: 1000;
}

div.rad_number_ca {
    padding: 0px 20px;
    font-size: 1.2em;
    letter-spacing: 1px;
    text-align: center;
    border: 0px solid red;
}

.rad_number_table {
    padding: 0px 20px;
    margin-bottom: 30px;
    border: 0px solid red;
    margin-bottom: 40px;
    border-bottom: 1px solid #5dc269;
}

.rad_number_table_PME {
    text-align: center;
    display: block;
    border: 0px solid red;
    font-size: 0.9em;
    color: #5dc269;
    margin-top: 10px;
    margin-bottom: 40px;
}

table.tb {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 0.9em;
    font-family: sans-serif;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
    border: 0px solid #5dc269 !important;
}

table table {
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
}

table thead th.tb {
    /* background-color: #253526aa; */
    border: 0px solid #5dc269 !important;
}

table th.tb,
table td.tb {
    padding: 10px 5px !important;
    box-sizing: border-box;
    border-left: 1px solid #ffffff66;
    /* background-color: #253526aa; */
    border: 0px solid #5dc269 !important;
}

th.tb img {
    width: 40px;
    display: inline;
}

div.tb_ {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}