.projectStatus {
    margin-bottom: 90px;
    border: 0px solid red;
}

.projectStatus_ {
    margin: 0px;
}

.status-name {
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 20px;
    color: #000000aa;
    font-size: 1.1em;
    letter-spacing: 1px;
    border: 0px solid blue;
}

.projects_ {
    padding-bottom: 0px;
}

.projects__ {
    border-bottom: 1px solid #00000022;
}

.project-name {
    background-color: #6ed87400;
    border: 1px solid #6ed874;
    color: #6ed874;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: inline-block;
    text-align: center;
}