.radExpertise_container {
    display: block;
    margin-top: 20px;
}

.radExpertise_content {
    margin-bottom: 50px;
    margin-top: 70px;
}

.radExpertise {
    display: inline-block;
    background-color: #5dc269;
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    padding: 5px 20px;
    margin-right: 10px;
    margin-bottom: -2px;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.radExpertise_title {
    margin-top: 20px;
}

.radExpertise_title_button {
    margin-top: 20px;
    margin-left: -10px;
    margin-bottom: 30px;
}

div.add_content_editor2 {
    min-height: 200px;
    margin-top: 0px;
}

div.add_content_editor2 div.ck-editor__main {
    min-height: 200px !important;
}

div.add_content_editor2 div.ck-content {
    min-height: 200px !important;
}

.updateRadContentButton {
    margin-left: -10px;
}