        @media( max-width: 1150px) {
            .nav_icon_ {
                width: 45px;
                height: 45px;
            }
            .nav_icon_text_name {
                font-size: 1.6em;
            }
            .nav_icon_text_slug {
                font-size: 0.68em;
                margin-top: 1px;
                /* margin-bottom: 10px; */
            }
        }
        
        @media ( max-width: 1050px) {
            .nav_ {
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
            .nav_icon {
                align-items: center;
                justify-content: center;
            }
            .nav_menu {
                border: 0px solid green;
                margin-left: 0px;
                position: relative !important;
            }
            .nav_menu {
                margin-top: -30px;
            }
        }
        
        @media( min-width: 830px) {
            .nav_menu {
                margin-left: 0px !important;
            }
        }
        
        @media( max-width: 900px) {}
        
        @media ( max-width: 830px) {
            .nav_ {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                z-index: 10000000;
            }
            .nav_icon {
                justify-content: flex-start;
            }
            .nav_menu {
                position: fixed !important;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #253526;
                overflow-y: auto;
                z-index: 10000000;
                margin-top: 0px;
            }
            .nav_menuIcon {
                margin-right: 10px;
                position: relative;
                opacity: 1;
                z-index: 1;
                visibility: visible;
                transform: scale(0);
                animation: 500ms animScale2 forwards;
                animation-delay: 1500ms;
            }
            .nav_menu_cross {
                position: relative;
                opacity: 1;
                z-index: 1;
                visibility: visible;
                float: right;
                margin-right: 20px;
                margin-top: 10px;
            }
        }
        
        @media( max-width: 600px) {
            div.contact_info_left_phone {
                font-size: 0.8em;
            }
            div.contact_info_left_mail {
                font-size: 0.8em;
            }
        }
        
        @media( max-width: 550px) {
            div.don {
                margin-top: 20px;
            }
            div.contact_info {
                flex-direction: column;
            }
            div.contact_info_left {
                justify-content: center;
                text-align: center;
                flex-direction: column;
            }
            div.contact_info_left_phone {
                margin-right: 0;
            }
            div.contact_info_left_mail {
                margin-top: 0px;
            }
            div.contact_info_right {
                margin-top: 20px;
            }
        }
        
        @media( max-width: 450px) {}
        
        @media( max-width: 400px) {}