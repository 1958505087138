@media (max-width: 702px) {
    div.OnActualityView_title {
        font-size: 1.2em;
    }
    div.OnActualityView_competence_title {
        font-size: 1em;
    }
}

@media (max-width: 400px) {
    div.OnActualityView_title {
        font-size: 1em;
    }
}