body {
    background-color: #ffffff;
    color: #000000;
}

div.userCard {
    transform: translate3d(0, 0, 0);
    max-height: 400px;
    min-height: 300px;
    width: 100%;
    height: 100%;
    box-shadow: 5px 5px 10px 3px #00000044;
    border-radius: 5px;
    position: relative;
    z-index: 10;
}

div.userCard:hover {
    transform: scale(1.2);
    z-index: 100000 !important;
    position: relative;
}

div.userCard:hover div.userCard_body_title {
    transform: translate3d(0, -100px, 0);
    color: #ffffff;
}

div.userCard:hover div.userCard_body_subtitle {
    transition-delay: 100ms;
    transform: translate3d(0, -100px, 0);
    color: #ffffff;
}

div.userCard:hover div.userCard_body {
    background: linear-gradient(#00000000, #00000011, #00000055, #00000088, #000000aa);
}

div.userCard:hover div.userCard_body_description {
    opacity: 1;
    margin-left: 0%;
    z-index: 100;
    transform: scale(0.9);
}

img.userCard_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    object-position: top;
}

div.userCard_body {
    background: linear-gradient(#ffffff00, #ffffff11, #ffffff55, #ffffff88, #ffffffaa);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 100;
}

div.userCard_body_title {
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em;
    margin-bottom: 10px;
    padding: 0px 20px;
}

div.userCard_body_subtitle {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    padding: 0px 15px;
    color: #000000aa;
    font-size: 0.8em;
}

div.userCard_body_description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px 10px;
    overflow: hidden;
    overflow-y: auto;
    font-size: 0.9em;
    background-color: #ffffffdd;
    /* margin-left: -100%; */
    opacity: 0;
    z-index: -10;
    box-shadow: 5px 5px 10px 3px #00000055;
    border-radius: 5px;
}

.grapp {
    padding: 20px;
}

.grapp_imgs {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.grapp_imgs div {
    border: 1px solid #00000011;
    margin: 20px;
    padding: 20px;
}

.grapp_imgs img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    display: block;
}

.tabStyle th h1 {
    font-weight: bold;
    font-size: 1em;
    text-align: left;
    color: #185875;
}

.tabStyle td {
    font-weight: normal;
    font-size: 1em;
    -webkit-box-shadow: 0 2px 2px -2px #5dc269;
    -moz-box-shadow: 0 2px 2px -2px #5dc269;
    box-shadow: 0 2px 2px -2px #5dc269;
}

.tabStyle {
    text-align: left;
    overflow: hidden;
    width: 80%;
    margin: 0 auto;
    display: table;
    padding: 0 0 4em 0;
}

.tabStyle td,
.tabStyle th {
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 2%;
}


/* Background-color of the odd rows */

.tabStyle tr:nth-child(odd) {
    background-color: #5dc269;
}


/* Background-color of the even rows */

.tabStyle tr:nth-child(even) {
    background-color: #ffffff;
}

.tabStyle th {
    background-color: #ffffff;
}

.tabStyle td:first-child {
    color: #FB667A;
}

.tabStyle tr:hover {
    background-color: #464A52;
    -webkit-box-shadow: 0 6px 6px -6px #5dc269;
    -moz-box-shadow: 0 6px 6px -6px #5dc269;
    box-shadow: 0 6px 6px -6px #5dc269;
}

.tabStyle td:hover {
    background-color: #5dc269;
    color: #403E10;
    font-weight: bold;
    box-shadow: #ffffff -1px 1px, #ffffff -2px 2px, #ffffff -3px 3px, #ffffff -4px 4px, #ffffff -5px 5px, #ffffff -6px 6px;
    transform: translate3d(6px, -6px, 0);
    transition-delay: 0s;
    transition-duration: 0.4s;
    transition-property: all;
    transition-timing-function: line;
}

@media (max-width: 800px) {
    .tabStyle td:nth-child(4),
    .tabStyle th:nth-child(4) {
        display: none;
    }
}