@keyframes animeOp1 {
    from {
        opacity: 0;
        transform: translate3d(0, 40px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

div.AboutUs {
    overflow: hidden;
}

div.AboutUs_section1_title {
    text-align: center;
    margin-top: 100px;
    font-weight: 600;
    letter-spacing: 6px;
    opacity: 0;
    transform: translate3d(0, 40px, 0);
    animation: animeOp1 1000ms forwards;
}

div.AboutUs_section1_subtitle {
    text-align: center;
    margin-top: 40px;
    font-size: 1.2em;
    letter-spacing: 2px;
    opacity: 0;
    transform: translate3d(0, 40px, 0);
    animation: animeOp1 1000ms forwards;
    animation-delay: 500ms;
}

span.AboutUs_section1_subtitle_ {
    font-weight: bold;
}

div.AboutUs_section1_contactUs {
    width: 100%;
    text-align: center;
    margin-top: 40px;
    opacity: 0;
    transform: translate3d(0, 70px, 0);
    animation: animeOp1 1000ms forwards;
    animation-delay: 1000ms;
}

div.AboutUs_section1_contactUs span {
    display: inline-block;
    margin: auto;
    text-align: center;
    padding: 20px 30px;
    color: #ffffff;
    letter-spacing: 2px;
    border-radius: 40px;
    user-select: none;
    cursor: pointer;
}

div.AboutUs_section1_contactUs span:hover {
    transform: scale(0.9);
    background-color: #ffffff !important;
    border: 1px solid #5dc269;
    color: #5dc269;
}

div.AboutUs_section2 {
    margin-top: 140px;
    display: flex;
    max-height: 400px;
    padding: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

div.AboutUs_section2_left {
    width: 100%;
    padding: 0px 20px;
}

div.AboutUs_section2_left_title {
    font-weight: bold;
    font-size: 1.9em;
    text-align: center;
    margin-top: 20px;
    line-height: 1.5em;
}

div.AboutUs_section2_left_title span {
    color: #5dc269;
}

div.AboutUs_section2_left_text {
    font-size: 1.2em;
    text-align: center;
    max-width: 500px;
    margin: auto;
    margin-top: 40px;
    color: #00000077
}

div.AboutUs_section2_left_logo {
    max-width: 150px;
    text-align: center;
    margin: auto;
    margin-top: 30px;
    border-radius: 15px;
}

div.AboutUs_section2_left_logo img {
    width: 100%;
    object-fit: contain;
    display: block;
    margin: auto;
}

div.AboutUs_section2_right {
    width: 100%;
    padding: 0px 20px;
}

div.AboutUs_section2_right_image {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

img.AboutUs_section2_right_image_ {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

div.AboutUs_section3 {
    margin-top: 140px;
}

div.AboutUs_section3_title {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.AboutUs_section3_title_text {
    border: 0px solid red;
    display: inline-block;
    margin: 0 !important;
    padding: 0px 10px;
    letter-spacing: 2px;
    font-size: 2em;
    text-align: center;
}

div.AboutUs_section3_title_line {
    width: 15px;
    height: 2px;
    background-color: #5dc269;
    margin-top: 5px;
}

div.AboutUs_section3_body {
    display: flex;
    max-width: 1100px;
    margin: auto;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-top: 80px;
    flex-wrap: wrap;
    padding: 10px 30px;
}

div.AboutUs_section3_body_card {
    width: 23%;
    min-height: 300px;
    max-height: 400px;
    padding: 0px 10px;
    padding-bottom: 40px;
}

div.AboutUs_section3_body_card:hover {
    position: relative;
    z-index: 10;
}