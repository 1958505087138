@keyframes animeOp1 {
    from {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rotateGlobe {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

div.projectsView {
    overflow: hidden;
}

div.projectsView_Section1 {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

div.projectsView_Section1_ {
    display: flex;
    border: 0px solid blue;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: auto;
    transform: translate3d(0, 100px, 0);
    animation: animeOp1 2000ms forwards;
    text-align: center;
}

img.globe {
    width: 80px;
    object-fit: contain;
    display: block;
    /* animation: 5000ms rotateGlobe infinite; */
}

span.projectsView_Section1_text {
    margin-left: 10px;
    letter-spacing: 2px;
    font-family: 'Roboto-Regular';
}

div.projectsView_Section1_button {
    width: 100%;
    text-align: center;
    margin-top: 40px;
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    animation: animeOp1 1000ms forwards;
    animation-delay: 1000ms;
}

div.projectsView_Section1_button span {
    display: inline-block;
    margin: auto;
    text-align: center;
    padding: 15px 50px;
    color: #ffffff;
    letter-spacing: 2px;
    border-radius: 40px;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
}

div.projectsView_Section1_button span:hover {
    transform: scale(0.9);
    background-color: #ffffff !important;
    border: 1px solid #5dc269;
    color: #5dc269;
}

div.projectsView_Section2 {
    max-width: 1200px;
    margin: auto;
    padding: 0px 10px;
    margin-bottom: 40px;
}

div.projectsView_Section2_titleContainer {
    border-bottom: 1px solid #5dc269;
    width: 100%;
    display: flex;
}

div.projectsView_Section2_title {
    margin: 0px;
    background-color: #5dc269;
    border-width: 0px;
    padding: 15px 30px;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 1.2em;
}

div.projectsView_Section2_body {
    margin-top: 30px;
    min-height: 200px;
}

div.projectsView_Section2_body_ {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 100px;
}

div.projectsView_Section2_body_project {
    width: 30%;
    padding-bottom: 30px;
    padding-right: 20px;
}