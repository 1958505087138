@font-face {
    font-family: 'Sofia-pro-black';
    src: url('../fonts/Sofia/Sofia-Pro-Black-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Sofia-pro-regular';
    src: url('../fonts/Sofia/Sofia-Pro-Regular-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Sofia-pro-regular-italic';
    src: url('../fonts/Sofia/Sofia-Pro-Regular-Italic-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Sofia-pro-extraLight';
    src: url('../fonts/Sofia/Sofia-Pro-ExtraLight-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Sofia-Pro-ExtraLight';
    src: url('../fonts/Sofia/Sofia-Pro-ExtraLight-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Black';
    src: url('../fonts/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'RockSalt-Regular';
    src: url('../fonts/Rock_Salt/RockSalt-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

body {
    font-family: 'Lato-Regular';
}