div.donateView {
    transform: translate3d(0, 0, 0);
    min-height: 100%;
}

div.banniere {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 0px solid red;
    overflow: hidden;
}

div.banniere img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

div.donate_section1 {
    position: relative;
    width: 100%;
    margin: 0px auto;
    z-index: 1000;
    padding: 20px;
    padding-top: 80px;
    min-height: 400px;
    background-color: #00000055;
}

div.donate_section1_title {
    font-weight: bold;
    font-size: 3em;
    font-family: 'Lato-Black';
    color: #ffffff;
    letter-spacing: 1px;
    line-height: 1.6em;
    text-align: center;
}

div.donate_section1_subtitle {
    color: #ffffff;
    font-size: 1.2em;
    text-align: center;
    line-height: 1.6em;
}

div.donate_section2 {
    width: 100%;
    padding: 20px;
    display: flex;
}

div.donate_section2_logo {
    display: block;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

span.donate_section2_logo_title {
    font-size: 6em;
    font-family: 'Lato-Black';
    color: #5dc269;
}

span.donate_section2_logo_subTitle {
    font-family: 'Lato-Black';
    color: #5dc269;
    letter-spacing: 1px;
    font-size: 1.5em;
}

div.donate_section2_card_container {
    width: 100%;
}

div.donate_section2_card {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    /* background-color: #5dc269; */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
    box-shadow: 0px 10px 10px 10px #00000011;
}

div.donate_section2_card_title {
    text-align: center;
    font-family: 'Lato-Black';
    color: #00000099;
    font-size: 1.7em;
    line-height: 1.5em;
    margin-top: 20px;
}

div.donate_section2_card_img img {
    max-width: 100%;
    object-fit: contain;
}

div.donate_section2_card_button {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.donate_section2_card_button span {
    background-color: #ffffff;
    border: 1px solid #5dc269;
    padding: 20px 40px;
    display: inline-block;
    border-radius: 40px;
    margin-top: 20px;
    color: #5dc269;
    font-family: 'Lato-Black';
    font-weight: bold;
    letter-spacing: 1.3px;
    cursor: pointer;
}

div.donate_section2_card_button span:hover {
    background-color: #5dc269;
    border: 1px solid #5dc269;
    color: #ffffff;
}

div.donate_section3 {
    padding: 0px 40px;
}

div.donate_section3_title {
    /* color: #5dc269; */
    /* font-weight: bold; */
    text-align: center;
    font-size: 1.6em;
    max-width: 600px;
    margin: auto;
    margin-top: 20px;
    letter-spacing: 0px;
}

div.donate_section3_cardContainer {
    display: flex;
    margin-top: 70px;
    justify-content: center;
    margin-bottom: 90px;
}

div.donate_section3_card {
    padding: 0px 10px;
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
}