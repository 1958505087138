@media ( max-width: 855px) {
    div.card3_title {
        font-size: 1.1em;
    }
    div.card3_content {
        font-size: 1em;
    }
}

@media ( max-width: 340px) {
    div.card3_title {
        font-size: 1.1em;
    }
    div.card3_content {
        font-size: 0.8em;
        padding: 30px 20px;
    }
}