@media( max-width: 1090px) {
    .shortDescriptionOfRad_left {}
    .card {}
    .card_left {}
    .cardIcon {
        font-size: 2em !important;
        color: #5dc269;
    }
    .card_right {
        line-height: 1.6em;
    }
    .card_title {
        font-weight: bold;
        font-size: 1.1em;
        color: #5dc269;
    }
    .card_text {
        margin-top: 10px;
        font-size: 1em;
    }
    .card_text p {
        margin-top: 10px;
    }
    .shortDescriptionOfRad_right_title {
        font-size: 2.5em;
    }
    .shortDescriptionOfRad_right_text {
        font-size: 1em;
        text-align: justify !important;
        line-height: 1.6em !important;
    }
    .shortDescriptionOfRad_right_logo {
        border: 0px solid red;
        padding: 20px;
    }
    .shortDescriptionOfRad_right_logo_ {
        max-width: 150px;
        object-fit: contain;
        border-radius: 100%;
    }
    .shortDescriptionOfRad_right_subTitle {
        font-size: 1.3em;
    }
}

@media( max-width: 930px) {
    .shortDescriptionOfRad {
        flex-direction: column-reverse;
    }
    .shortDescriptionOfRad_right {
        margin-top: 40px;
    }
}

@media( max-width: 450px) {
    .card_title {
        font-size: 1.1em !important;
    }
    div.shortDescriptionOfRad_right_domaine_one {
        font-size: 1em;
    }
    .shortDescriptionOfRad_right_text {
        padding: 0px;
    }
    .card {
        padding-left: 0px;
        padding-right: 0px;
    }
}