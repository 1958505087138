img.OnCompetenceView_top_odd_ {
    width: 100%;
    max-height: 100vh;
    object-fit: cover;
    margin-top: 20px;
    object-position: top;
}

div.OnCompetenceView_title {
    text-align: center;
    margin-top: 40px;
    font-size: 2.7em;
    letter-spacing: 1.5px;
    line-height: 1.6em;
}

div.OnCompetenceView_subTitle {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 70px;
    font-size: 2em;
    letter-spacing: 1.5px;
    line-height: 1.6em;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    font-weight: normal;
}

div.OnCompetenceView_subTitle span {
    border-bottom: 1px solid #5dc269;
    padding-bottom: 3px;
}

div.OnCompetenceView_subTitle a:hover {
    /* display: inline-block;
    transform: scale(0.9); */
    color: #45944f;
}