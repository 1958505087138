@media (max-width: 1090px) {
    div.AboutUs_section1_title {
        font-size: 2.7em;
    }
    div.AboutUs_section3_body_card {
        width: 25%;
    }
}

@media (max-width: 895px) {
    div.AboutUs_section3_body_card {
        width: 45%;
    }
}

@media (max-width: 570px) {
    div.AboutUs_section3_body_card {
        width: 50%;
    }
}

@media (max-width: 500px) {
    div.AboutUs_section3_body_card {
        width: 90%;
    }
    div.AboutUs_section1_contactUs span {
        font-size: 0.9em;
    }
}

@media (max-width: 1070px) {
    div.AboutUs_section2_left_title {
        font-size: 1.6em;
    }
}

@media (max-width: 980px) {
    div.AboutUs_section2_left_title {
        font-size: 1.5em;
    }
    div.AboutUs_section2_left_text {
        font-size: 1em;
    }
}

@media (max-width: 860px) {}

@media (max-width: 850px) {
    div.AboutUs_section2 {
        flex-direction: column-reverse;
        max-height: none;
        margin-top: 80px;
    }
    div.AboutUs_section2_left {
        margin-top: 40px;
    }
}

@media (max-width: 600px) {
    div.AboutUs_section1_title {
        font-size: 2.5em;
    }
    div.AboutUs_section3 {
        margin-top: 90px;
    }
    div.AboutUs_section3_body {
        margin-top: 40px;
    }
}

@media (max-width: 500px) {
    div.AboutUs_section1_title {
        font-size: 1.5em;
    }
    div.AboutUs_section1_title {
        letter-spacing: 4px;
    }
    div.AboutUs_section1_subtitle {
        letter-spacing: 1px;
    }
    div.AboutUs_section1_contactUs span {
        letter-spacing: 2px;
    }
    div.AboutUs_section3_title_text {
        padding: 0px 5px;
    }
    div.AboutUs_section3_title_line {
        width: 10px;
    }
}

@media (max-width: 478px) {
    div.AboutUs_section2_left_title {
        font-size: 1.5em;
    }
    div.AboutUs_section2_left_text {
        font-size: 1em;
    }
    div.AboutUs_section3_title_text {
        padding: 0px 20px;
    }
    /* div.AboutUs_section3_title_line {
        width: 5px;
    } */
    div.AboutUs_section3_title_line {
        width: 0px;
    }
}

@media (max-width: 380px) {
    div.AboutUs_section2_left_title {
        font-size: 1.5em;
    }
    div.AboutUs_section2_left_text {
        font-size: 0.8em;
    }
    div.AboutUs_section2_left_logo {
        max-width: 120px;
    }
    div.AboutUs_section3_title_line {
        width: 0px;
    }
    div.AboutUs_section3_title_text {
        padding: 0px 20px;
    }
}