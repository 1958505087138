div.imageSelector {
    margin-top: 40px;
}

div.imageSelector_dragDrop {
    width: 100%;
    max-width: 400px;
    width: 400px;
    height: 300px;
    border: 1px solid #5dc269;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transform: translate3d(0, 0, 0);
}

img.imageSelector_dragDrop_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

div.imageSelector_dragDrop span {
    color: #ffffff;
    background-color: #5dc269aa;
    position: relative;
    z-index: 1000;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: bold;
}

div.imageSelector_input {
    width: 100%;
    max-width: 400px;
    margin: auto;
    margin-top: 30px;
    transform: translate3d(0, 0, 0);
}

div.imageSelector_input span {
    display: block;
    width: 100%;
    text-align: center;
    background-color: #5dc269;
    color: #ffffff;
    padding: 15px 20px;
    border-radius: 5px;
}

div.imageSelector_input input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    outline: none;
    cursor: pointer;
}