.KnowHowView {
    opacity: 0;
    animation: opacity_anim 1000ms forwards;
    animation-delay: 1s;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    border: 0px solid red;
    /* overflow: hidden; */
}

.KnowHowView_competences_container {
    padding: 0px 20px;
    margin-top: -30px !important;
    border: 0px solid red;
    width: 100%;
    max-width: 1300px;
    margin: auto;
}

.KnowHowView_competences {
    display: inline;
    /* border: 1px solid red; */
}

.KnowHowView_competences a {
    display: inline;
    color: #373737;
    font-family: Open Sans, serif;
    /* line-height: 1.3; */
    font-weight: 700 !important;
    /* margin: .67em 0; */
    /* margin-bottom: .625em; */
    letter-spacing: 1px;
    display: block;
    white-space: nowrap;
}

@media( max-width: 360px) {
    .KnowHowView_competences a {
        white-space: initial;
    }
}

.KnowHowView_competences_container_ {
    letter-spacing: 1px;
    line-height: 1.3;
}

.KnowHowView_text {
    text-align: justify;
    max-width: 900px;
    padding: 0px 20px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 60px;
    color: #373737;
    font-family: Open Sans, serif;
    margin-top: 0px;
}

.KnowHowView_competence_presentation {
    font-weight: bold;
    padding: 0px 20px;
    text-align: center;
    font-family: Open Sans, serif;
    color: #373737;
}

.KnowHowView_competence_presentation span {
    font-size: 1.1em;
}

.KnowHowView_competence_presentation_content {
    padding: 0px 20px;
    max-width: 1200px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 40px;
}

.competence_illustrator_2 {
    width: 100%;
    margin-top: 0px;
    background-color: #ffffff;
    padding: 80px 40px;
    padding-bottom: 40px;
    /* border: 1px solid red; */
    /* min-height: 100vh; */
}

.competence_illustrator svg {
    max-height: 300px;
    max-width: 100%;
}

.card_container {
    width: 100%;
    padding: 0px;
    padding-top: 20px;
    /* border: 1px solid red; */
}

.card_list_content {
    width: 100%;
    max-width: 350px;
    padding: 0px 10px;
    margin-bottom: 30px;
    z-index: 10;
}

.card_container-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    font-size: 0.9em;
    letter-spacing: 1px;
    color: #5F5F5F !important;
}

.card_container-title-text {
    color: #5dc269 !important;
}

.card_container-title-square {
    width: 20px;
    height: 20px;
    background-color: #5dc269;
    margin-left: 20px;
}

.card_list {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


/* .competence_illustrator div {
    margin-top: 80px;
    text-align: center;
    max-width: 900px;
}

.competence_illustrator div span {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 0.9em;
    line-height: 2em;
    color: #5d5c5c;
} */

.KnowHowView_testimony {
    margin: auto;
    min-height: 400px;
    max-width: 1200px;
    padding: 20px;
    display: flex;
    align-items: center;
}