@media (max-width: 965px) {
    div.projectsView_Section2_body {
        padding: 0px 10px;
    }
    div.projectsView_Section2_body_ {
        justify-content: center;
    }
    div.projectsView_Section2_body_project {
        width: 50%;
        padding: 10px 10px;
        max-width: 400px;
    }
}

@media (max-width: 550px) {
    div.projectsView_Section2_body_project {
        width: 100%;
        padding: 10px 20px;
    }
}

@media (max-width: 460px) {
    img.globe {
        width: 50px;
    }
    span.projectsView_Section1_text {
        font-size: 2em;
    }
    div.projectsView_Section2_title {
        font-size: 1.1em;
    }
}

@media (max-width: 350px) {
    img.globe {
        width: 30px;
    }
    span.projectsView_Section1_text {
        font-size: 1.7em;
    }
    div.projectsView_Section2_title {
        font-size: 1em;
    }
}

@media (max-width: 270px) {
    img.globe {
        width: 0px;
    }
    span.projectsView_Section1_text {
        font-size: 1.7em;
    }
}