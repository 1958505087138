div.OnProjectsView {
    overflow: hidden;
}

div.OnProjectsView_top_text {
    text-align: center;
    padding: 30px 20px;
    padding-bottom: 10px;
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    animation: animeOp1 1000ms forwards;
}

span.OnProjectsView_top_text_ {
    font-size: 3em;
}

div.OnProjectsView_top_subtext {
    text-align: center;
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    animation: animeOp1 1000ms forwards;
    animation-delay: 200ms;
}

div.OnProjectsView_top_competence {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0px 10px;
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    animation: animeOp1 1000ms forwards;
    animation-delay: 400ms;
}

.OnProjectsView_top_competence_ {
    margin: 10px 2px;
    border: 0px solid red;
}

.OnProjectsView_top_competence_ span {
    background-color: #5dc269;
    padding: 10px 15px;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.7em;
    border-radius: 3px;
}

div.OnProjectsView_image {
    margin: 0;
    padding: 0px 20px;
    max-height: 400px;
    text-align: center;
    width: 100%;
    border: 0px solid red;
    overflow: hidden;
    max-width: 900px;
    margin: auto;
    margin-top: 40px;
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    animation: animeOp1 1000ms forwards;
    animation-delay: 600ms;
}

img.OnProjectsView_image_ {
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    object-fit: contain;
    width: 100%;
    height: auto;
    border-collapse: collapse;
}

div.OnProjectsView_odd {
    margin: 0x;
    width: 100%;
    max-width: 900px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    padding: 0px 20px;
    border-width: 0;
    border-collapse: collapse;
    margin-top: 10px;
}

div.OnProjectsView_odd_ {
    width: 20%;
    max-width: 60px;
    margin-left: 5px;
    margin-top: 2px;
}

img.OnProjectsView_odd_img {
    width: 100%;
}

div.OnProjectsView_info {
    display: flex;
    padding: 0px 20px;
    max-width: 900px;
    margin: auto;
    margin-top: 40px;
    border: 0px solid red;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

div.OnProjectsView_info_calendarIcon {
    border: 0px solid #5dc269;
}

.calendarIcon {
    color: #5dc269;
    font-size: 2.7em !important;
}

div.OnProjectsView_info_text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    text-align: center;
    overflow: hidden;
}

span.OnProjectsView_info_text_top {
    font-size: 2em;
    font-weight: bold;
}

span.OnProjectsView_info_text_bottom {
    color: #000000dd;
    font-size: 0.6em;
    font-weight: bold;
    letter-spacing: 1px;
}

div.OnProjectsView_progress {
    max-width: 900px;
    margin: auto;
    margin-top: 50px;
    padding: 0px 20px;
    margin-bottom: 40px;
}

div.OnProjectsView_progress_text {
    font-weight: bold;
    font-size: 1em;
    letter-spacing: 1px;
    color: #5dc269;
}

div.OnProjectsView_progress_bare {
    width: 100%;
    border: 0px solid red;
    height: 30px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    transform: translate3d(0, 0, 0);
}

div.OnProjectsView_progress_bare_line {
    width: 100%;
    height: 3px;
    background-color: #00000011;
    position: relative;
    z-index: 5;
}

div.OnProjectsView_progress_bare_linePlain {
    position: absolute;
    top: 0;
    height: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    z-index: 6;
}

div.OnProjectsView_progress_bare_linePlain_ {
    width: 100%;
    height: 10px;
    border-radius: 0px;
    background-color: #5dc269;
}

div.OnProjectsView_progress_bare_linePlain::after {
    background-color: #ffffff;
    content: "";
    height: 15px;
    left: 100%;
    position: absolute;
    top: 15;
    -webkit-transform-origin: 0 10%;
    transform-origin: 0 10%;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    width: 4px;
    margin-left: 0px;
    z-index: 0;
}


/* div.OnProjectsView_progress_bare_linePlain_pont {
    width: 15px;
    height: 15px;
    margin-left: -5px;
    border-radius: 100%;
    background-color: #5dc269;
    position: absolute;
    right: 0;
} */

div.OnProjectsView_progress_bare_linePlain_value {
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 0px;
    /* background-color: #5dc269; */
    position: relative;
    left: 0;
    /* padding: 5px 10px; */
    color: #5dc269;
    font-weight: bold;
    float: left;
    border: 0px solid red;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

span.OnProjectsView_progress_bare_linePlain_dateStart {
    /* font-weight: normal;
    font-size: 0.8em; */
}

div.OnProjectsView_body {
    max-width: 900px;
    margin: auto;
    margin-top: 80px;
    padding: 0px 20px;
    margin-bottom: 40px;
}

div.OnProjectsView_body_head_text {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.5em;
    border-bottom: 1px solid #5dc269;
    padding-bottom: 10px;
    font-family: Lato-Black;
}

div.OnProjectsView_body_head2 {
    margin-top: 50px;
}

div.OnProjectsView_body_subDescription {
    margin-top: 20px;
    color: #00000099;
}

div.OnProjectsView_body_description {
    margin-top: 20px;
    color: #00000099;
}

div.OnProjectsView_body_galerie {
    overflow: hidden;
    border: 0px solid red;
    margin-top: 40px;
}

div.OnProjectsView_body_actuality_ {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

div.OnProjectsView_body_actuality_card {
    width: 33%;
    padding: 10px 10px;
}

div.OnProjectsView_body_footer {
    text-align: right;
    margin-top: 120px;
    letter-spacing: 1px;
}

.OnProjectsView_body_footer_text {
    display: block;
    margin-top: 10px;
    color: #5dc269;
    font-size: 1em;
}