@media( max-width: 1150px) {
    li.menu_item {
        margin: 0px 10px;
    }
    span.menu_item_title {
        font-size: 0.6em;
    }
    .menu_item_subMenu_text {
        font-size: 0.7em;
    }
}

@media ( max-width: 1050px) {
    ul.menu {
        justify-content: center;
    }
}

@media ( max-width: 830px) {
    ul.menu {
        flex-direction: column;
        justify-content: flex-start;
    }
    li.menu_item {
        margin: 0px 10px;
    }
    span.menu_item_title {
        font-size: 0.9em;
    }
    .menu_item_subMenu_text {
        font-size: 0.9em;
    }
    li.menu_item {
        padding: 20px 0px;
    }
    ul.menu_item_subMenu {
        background-color: #ffffff00;
        margin-left: 0px;
        margin-top: 10px;
        display: inline-block;
        padding: 20px 40px;
        position: relative;
        transform: scaleY(1);
        transform-origin: 0 0;
        padding-bottom: 0px;
    }
}

@media( max-width: 900px) {}

@media( max-width: 700px) {}

@media( max-width: 500px) {}

@media( max-width: 450px) {}

@media( max-width: 400px) {}