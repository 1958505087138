div.Slider_container {
    width: 100%;
    transform: translate3d(0, 0, 0);
}

div.Slider_container_ {
    white-space: nowrap;
    overflow: hidden;
}

a.Slider_container_item {
    display: inline-block;
    border: 0px solid red;
    width: 100%;
    padding: 20px;
}

div.Slider_container_item_logo {
    max-width: 120px;
}

img.Slider_container_item_logo_ {
    width: 100%;
    object-fit: contain;
    image-rendering: optimizeQuality;
}

div.Slider_container_item_slug {
    width: 100%;
    height: auto;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-top: 10px;
}

div.Slider_container_item_slug p {
    font-weight: bold;
    color: #253526;
    font-size: 1.2em;
}

div.Slider_container_item_description {
    width: 100%;
    height: auto;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-top: 10px;
}

div.Slider_container_item_description p {
    color: #00000088;
}

div.Slider_container_controller {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 10px;
    border: 0px solid red;
    position: relative;
    z-index: 1000;
    user-select: none;
}

div.Slider_container_controller_ {
    width: 35px;
    height: 35px;
    font-weight: bold;
    font-size: 0.8em;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px solid #5dc26900;
    margin: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 5px 5px 5px 1px #00000055;
}