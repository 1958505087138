div.ContactUsView_map {
    max-height: 100vh;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

div.ContactUsView_map::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    background: #00000011;
    z-index: 999;
}

div.mapInfoContainer {
    position: absolute;
    left: 0;
    top: 0;
    background: #00000000;
    z-index: 999;
}

div.mapInfoContainer_ {
    width: 70%;
    max-width: 350px;
    margin-top: 10px;
    margin-left: 10px;
}

div.mapInfoContainer_head {
    background-color: #5dc269;
    color: #ffffff;
    text-align: center;
    padding: 10px 20px;
}

@keyframes animTopBottom {
    0% {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, -20px, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

div.scrollToForm {
    position: absolute;
    top: 0;
    right: 0;
    border: 0px solid red;
    z-index: 1000;
    color: #000000;
    margin-top: 100px;
    margin-right: 20px;
    width: 60px;
    height: 60px;
    /* background-color: #ffffffaa; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    animation: animTopBottom 1s infinite;
    cursor: pointer;
}

div.scrollToForm svg {}

div.mapInfoContainer_body {
    background-color: #ffffff;
    padding: 20px;
    font-size: 0.8em;
    line-height: 1.6em;
    box-shadow: 10px 10px 20px 10px #00000011;
}

div.mapInfoContainer_body_horaire {
    font-size: 0.8em;
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    color: #00000088;
}

form.contact-us-form {
    margin-top: 30px;
}

div.ContactUsView_form_ {
    display: flex;
    max-width: 1200px;
    margin: auto;
    border: 0px solid red;
    margin-top: 40px;
    padding: 0px 20px;
}

div.ContactUsView_form_left {
    width: 100%;
}

div.ContactUsView_form_left_title {
    color: #5dc269;
    font-weight: bold;
    font-size: 1.4em;
    letter-spacing: 1px;
}

div.ContactUsView_form_right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0px solid red;
}

img.ContactUsView_form_right_img {
    width: 90%;
    max-width: 200px;
}

.contact-us-form-input {
    border: 0px solid #00000000;
    border-bottom: 1px solid #00000055;
    resize: none;
    padding: 20px 0px;
    width: 100%;
    outline: none;
}

.contact-us-form-area {
    border: 1px solid #00000055;
    border-radius: 5px;
    padding: 20px;
    height: 200px;
    margin-top: 50px;
}

div.contact-us-form_ {
    margin-bottom: 20px;
}

span.ContactUsView_form_right_radText {
    font-size: 1.9em;
}

.contact-us-form_submit_ {
    background-color: #5dc269;
    color: #ffffff;
    padding: 15px 40px;
    border: 1px solid #5dc269;
    border-radius: 2px;
    display: block;
    margin: auto;
    outline: none;
}

.contact-us-form_submit_:hover {
    transform: scale(0.8);
}

div.contact-us-form_submit {
    margin-bottom: 30px;
}