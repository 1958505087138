@media ( max-width: 960px) {
    div.ActuView_more_card {
        width: 33.33%;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 30px;
    }
}

@media ( max-width: 830px) {
    div.ActuView_current {
        flex-direction: column;
    }
    div.ActuView_current_left {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
    }
    img.ActuView_current_left_img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    div.ActuView_current_right {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0%;
    }
    div.ActuView_current_right_ {
        padding-left: 20px;
        padding-top: 20px;
    }
}

@media ( max-width: 760px) {
    div.ActuView_more_card {
        width: 50%;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 30px;
    }
    div.ActuView_more {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media ( max-width: 600px) {
    div.ActuView_current_right_title {
        font-size: 1.1em;
        letter-spacing: 1.5px;
        line-height: 1.6em;
    }
    div.ActuView_current_right_body {
        line-height: 1.6em;
        margin-top: 20px;
        color: #00000099;
        font-size: 0.9em;
    }
}

@media ( max-width: 520px) {
    div.ActuView_more_card {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 30px;
    }
    div.ActuView_more {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media ( max-width: 450px) {
    div.ActuView_current_right_title {
        font-size: 1em;
        letter-spacing: 1.5px;
        line-height: 1.6em;
    }
    div.ActuView_current_right_body {
        line-height: 1.6em;
        margin-top: 20px;
        color: #00000099;
        font-size: 0.8em;
    }
}