div.add_head {
    text-align: center;
    padding: 20px;
    color: #000000aa;
    font-size: 1.5em;
    letter-spacing: 1px;
}

div.add_content {
    padding: 20px;
}

div.add_content_label {
    color: #5dc269;
    font-size: 1.2em;
    letter-spacing: 0px;
}

div.add_content_label span {
    /* border: 1px solid #5dc269;
    padding: 3px;
    padding: 10px 20px;
    border-radius: 5px; */
}

div.add_content_input {
    margin-top: 10px;
}

div.add_content_input input {
    padding: 15px 20px;
    width: 100%;
    outline: none;
    max-width: 500px;
    border: 1px solid #00000099;
    border-radius: 5px;
}

div.add_content_input textarea {
    padding: 15px 20px;
    width: 100%;
    outline: none;
    max-width: 500px;
    border: 1px solid #00000099;
    border-radius: 5px;
    resize: none;
    height: 200px;
}

.add_content_checkBox {
    margin-top: 20px;
}

.add_content_checkBox_ {
    display: inline-flex;
    margin-right: 15px;
    margin-bottom: 10px;
    line-height: 1.7em;
    align-items: center;
}

.competenceCheckbox {
    border: 1px solid #5dc269;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
}

.oddCheckbox {
    border: 1px solid #5dc269;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.oddCheckbox:hover,
.competenceCheckbox:hover {
    transform: scale(1.1);
}

.add_content_checkBox_ label {
    /* color: #5dc269; */
    color: #000000aa;
    font-weight: 500;
    margin-left: 5px;
    user-select: none;
}

img.add_content_checkBox_odd {
    width: 120px;
}

div.add_content_editor {
    min-height: 500px;
    margin-top: 10px;
}

div.ck-editor__main {
    min-height: 500px !important;
}

div.ck-content {
    min-height: 500px !important;
}

div.previewImageSelected_container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}

.previewImageSelected_cross {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ffffffdd;
    cursor: pointer;
}

div.previewImageSelected_ {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 10px 10px 20px 5px #00000022;
    margin-left: 0px;
    margin-bottom: 30px;
    min-width: 200px;
    transform: translate3d(0, 0, 0);
    border: 0px solid red;
}

img.previewImageSelected {
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
}

div.previewImageContainer {
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 30px;
}

div.imageDescription {
    display: block;
    display: flex;
    justify-content: center;
    width: 100%;
    border: 0px solid red;
    padding: 0px 20px;
    margin-bottom: 50px;
}

div.imageDescription input {
    width: 100%;
    border: 1px solid #00000055;
    padding: 10px 20px;
    border-radius: 5px;
    outline: none;
}

div.add_button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;
}

div.add_button span {
    background-color: #5dc269;
    color: #ffffff;
    padding: 15px 40px;
    border-radius: 5px;
    text-align: center;
    display: block;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
}

div.add_button span:hover {
    transform: scale(0.9);
}

div.delete_all_image {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}

div.delete_all_image span.delete_all_image_text {
    background-color: red;
    text-align: right;
    color: #ffffff;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 0.9em;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

span.delete_all_image_text span {
    margin-right: 5px;
}

.add_content_date {
    display: flex;
}

.add_content_date_ {
    margin-right: 10px;
}

.add_content_date_ input {
    padding: 9px 10px !important;
}

.add_content_input select {
    width: 100%;
    max-width: 500px;
    padding: 15px;
    border-radius: 5px;
    background-color: transparent;
    outline: none;
}