div.ActuView {
    overflow: hidden;
}

div.ActuView_current {
    width: 100%;
    height: auto;
    min-height: 100vh;
    /* min-height: 400px; */
    display: flex;
    max-width: 1300px;
    margin: auto;
}

div.ActuView_current_left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}

img.ActuView_current_left_img {
    width: 100%;
    height: 100%;
    max-height: 90%;
    object-fit: cover;
}

div.ActuView_current_right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -10%;
}

div.ActuView_current_right_ {
    background-color: #ffffff;
    width: 100%;
    height: auto;
    padding: 20px;
    padding-left: 40px;
    padding-top: 40px;
    overflow-y: auto;
    /* box-shadow: 1px 1px 10px 1px #00000011; */
}

div.ActuView_current_right_title {
    font-size: 1.3em;
    letter-spacing: 1.5px;
    line-height: 1.6em;
}

div.ActuView_current_right_body {
    line-height: 1.6em;
    margin-top: 20px;
    color: #00000099;
    font-size: 1em;
}

div.ActuView_current_right_viewMore {
    margin-top: 30px;
}

div.ActuView_current_right_viewMore span {
    background-color: #5dc269;
    color: #ffffff;
    padding: 10px 30px;
    font-size: 0.7em;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 2px;
    cursor: pointer;
}

div.ActuView_current_right_date {
    margin-top: 30px;
    text-align: right;
    color: #00000055;
    font-weight: normal;
    letter-spacing: 1px;
    font-size: 0.8em;
}

div.ActuView_more {
    max-width: 1100px;
    margin: auto;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

div.ActuView_more_card {
    width: 30%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
}