div.projectCard {
    transform: translate3d(0, 0, 0);
    min-height: 300px;
    width: 100%;
    height: 100%;
    /* max-height: 500px; */
    /* box-shadow: 5px 5px 10px 3px #00000044; */
    box-shadow: 10px 10px 20px 2px #00000055;
    border-radius: 5px;
    overflow: hidden;
    user-select: none;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

div.projectCard:hover {
    transform: scale(1.1);
    z-index: 100;
}

div.projectCard_imageContainer {
    max-height: 300px;
    min-height: 200px;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
}

img.projectCard_image {
    width: 100%;
    height: 100%;
    max-height: 250px;
    min-height: 250px;
    object-fit: cover;
    object-position: top left;
}

div.projectCard_odd {
    background-color: #00000000;
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
    padding: 10px;
    padding-bottom: 8px;
    -webkit-box-direction: normal;
    box-direction: normal;
    line-height: 1.7em;
    width: 100%;
    /* justify-content: flex-end; */
}

div.odd {
    min-width: 45px;
    width: 11%;
    border: 0px solid red;
    display: inline-block;
    padding: 0px 2px;
}

div.odd:hover {
    transform: scale(1.2);
}

img.odd_ {
    width: 100%;
    object-fit: cover;
    /* margin: 0px 5px; */
}

div.projectCard_competence {
    background-color: #00000000 !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100%;
    padding: 0px;
    color: #ffffff;
    overflow-y: auto;
    margin-left: -100%;
    transition-duration: 700ms;
    max-height: 200px !important;
}

div.projectCard_competence::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #5dc26900;
    border: 0px solid #5dc26900;
    border-radius: 0px;
    position: fixed;
    left: 0;
}

div.projectCard_competence::-webkit-scrollbar-track {
    width: 5px;
    background-color: #5dc26900;
    border: 0px solid transparent;
}

div.projectCard_competence::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 30px;
    background-color: #5dc269;
    border: 0px solid transparent;
}

div.competence {
    padding: 5px 0px;
    line-height: 1.2em;
    margin: auto;
}

div.competence span {
    padding: 5px 20px;
    display: inline-block;
    border-radius: 0px;
    font-size: 0.8em;
    background-color: #5dc269;
    color: #ffffff;
    border-radius: 5px;
    margin-left: 5px;
    font-weight: bold;
}

div.competence span:hover {
    transform: translate3d(10px, 0px, 0px);
    font-weight: bold;
}

.link {
    color: #ffffff;
}

div.projectCard_body {
    padding: 10px 20px;
}

div.projectCard_body:hover div.projectCard_competence {
    margin-left: 0px;
}

div.projectCard_body_title {
    color: #5dc269;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 0.9em;
    line-height: 1.5em;
}

div.card_separator_line {
    width: 100%;
    height: 1px;
    background-color: #5dc269;
    margin: 5px 0px;
}

div.projectCard_body_text {
    width: 100%;
    letter-spacing: 0px;
    font-size: 0.8em;
    color: #00000077;
    max-height: 150px;
    /* background-color: red; */
    overflow-y: hidden;
    padding-bottom: 20px !important;
    transform: translate3d(0, 0, 0);
}

div.projectCard_body_text::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(#ffffff55, #ffffff);
}

div.projectCard_footer {
    padding: 0px 20px 20px 20px;
    font-size: 0.8em;
    color: #5dc269;
    font-weight: bold;
    text-align: right;
    border: 0px solid red;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

div.projectCard_footer span {
    color: #5dc269;
    font-weight: bold;
    text-align: right;
}