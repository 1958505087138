@media (max-width: 700px) {
    div.ContactUsView_form_right {
        position: absolute;
        transform: scale(0);
        opacity: 0;
        visibility: hidden;
    }
}

@media (max-width: 395px) {
    div.ContactUsView_form_left_title {
        font-size: 1.1em;
    }
}

@media (max-width: 325px) {
    div.ContactUsView_form_left_title {
        font-size: 1em;
    }
}