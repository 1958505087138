html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 250ms;
}

.MapCmp_container {
    width: 100vw;
    height: 100vh;
}

.leaflet-control-attribution {
    opacity: 0 !important;
    visibility: hidden !important;
    position: inherit !important;
    display: none !important;
    z-index: -10000 !important;
    transform: scale(0) !important;
}

#gmap_canvas {
    width: 100%;
    height: 100vh;
}