.PageSliderComponent_container {
    border: 0px solid red;
    max-width: 900px;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 20px;
}

.PageSliderComponent_container_table tbody {
    color: #5dc269;
    width: 100%;
}

.PageSliderComponent_container_table tbody tr {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.PageSliderComponent_container_table tbody tr td {
    border: 0px solid red;
    padding: 0;
    cursor: pointer;
}

.PageSliderComponent_container_table td button {
    min-width: 38px;
    height: 45px;
    border: 0.5px solid #00000022;
    cursor: pointer;
    background-color: #ffffff;
    color: #5dc269;
    padding: 10px;
    border-right: 0px solid #000000;
}

.chevron button {
    color: #000000aa;
    padding: 10px;
    cursor: pointer;
    border: 0.5px solid #00000022 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.PageSliderComponent_container_table td button:hover {
    font-weight: bold;
    background-color: #00000022;
}

.PageSliderComponent_container_table td.current button {
    background-color: #5dc269;
    color: #ffffff;
    font-weight: bold;
}

.chevron_left button {
    border-right: 0px solid #00000000 !important;
    border-radius: 5px 0px 0px 5px;
}

.chevron_left button span {
    color: #6c757d;
    pointer-events: none;
    /* background-color: red; */
    border-color: #000000aa;
    border: 2px solid #000000aa;
    width: 7px;
    height: 7px;
    display: block;
    border-top-width: 0px;
    border-right-width: 0px;
    transform: rotate(45deg);
}

.chevron_right button span {
    color: #6c757d;
    pointer-events: none;
    /* background-color: red; */
    border-color: #000000aa;
    border: 2px solid #000000aa;
    width: 7px;
    height: 7px;
    display: block;
    border-bottom-width: 0px;
    border-left-width: 0px;
    transform: rotate(45deg);
}