@media (max-width: 943px) {
    div.OnCompetenceView_title {
        font-size: 2em;
    }
}

@media (max-width: 716px) {
    div.OnCompetenceView_title {
        font-size: 1.5em;
    }
    div.OnCompetenceView_subTitle {
        font-size: 1.5em;
    }
}

@media (max-width: 522px) {
    div.OnCompetenceView_title {
        font-size: 1.2em;
    }
    div.OnCompetenceView_subTitle {
        font-size: 1.3em;
    }
}