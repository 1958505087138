.Video_container {
    width: 100%;
    max-height: 400px;
    min-height: 300px;
    /* border: 1px solid red; */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    /* box-shadow: 10px 10px 5px 1px #00000055; */
}

.videoComponent_video {
    width: 100%;
    height: 100%;
    border: 0px solid red;
    object-fit: contain;
    image-rendering: optimizeQuality;
    object-position: top center;
    display: inline;
    border-radius: 5px;
    /* box-shadow: 10px 10px 5px 1px #00000055; */
}

.controller {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10px;
}

.controller_playPause_ {
    font-size: 1em;
}

.controller_playPause_ button {
    background-color: transparent;
    color: #5dc269;
    border: 0px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    cursor: pointer;
}

.controller_rangeOther {
    width: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    border: 0px solid red;
    transform: translate3d(0, 0, 0);
}

.controller_rangeOther_range {
    width: 100%;
    padding: 0px 10px;
    transform: translate3d(0, 0, 0);
}

.controller_rangeOther_range input,
.video_volume_content input {
    height: 21px;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent!important;
    outline: none;
    border: none;
    display: block;
    position: relative;
    width: 100%;
    font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu, Cantarell, Fira Sans, sans-serif;
    margin: 0;
    direction: ltr;
    color: #262626;
    unicode-bidi: isolate;
    color: rgb(157, 150, 142);
    cursor: default;
    padding: initial;
    -webkit-writing-mode: horizontal-tb;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
    text-decoration: none;
    -webkit-rtl-ordering: logical;
    font: 400 13.3333px Arial;
    user-select: text;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "kern";
    border-radius: 4px;
    background-color: #5dc269;
    /* border: 1px solid red; */
    position: relative;
    z-index: 1;
}

.volume_range {
    writing-mode: bt-lr;
    /* IE */
    -webkit-appearance: slider-vertical !important;
    -moz-appearance: slider-vertical !important;
    appearance: slider-vertical;
    /* Chromium */
    height: 100% !important;
}

input[type='range'] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: #5dc269;
    background: #5dc269;
}

input[type='range']::range-progress {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: #5dc269;
    background: #5dc269;
}

input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #5dc269;
    background-color: #5dc26955;
    height: 3px;
}

input[name='volume']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    color: #5dc269 !important;
    background-color: #5dc26900 !important;
    /* width: 3px !important; */
}

input[type='range']::-webkit-slider-thumb {
    width: 12px;
    -webkit-appearance: none;
    height: 12px;
    /* cursor: ew-resize; */
    background: #5dc269;
    border-radius: 100%;
    margin-top: -5px;
}

input[type=range]:hover::-webkit-slider-runnable-track {
    background: #cccccc44;
}


/** FF*/

input[type="range"]::-moz-range-progress {
    background-color: #5dc269;
}

input[type="range"]::-moz-range-track {
    background-color: #5dc26955;
}

input[type='range']::-moz-range-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    /* cursor: ew-resize; */
    background: #5dc269;
    border-radius: 100%;
}


/* IE*/

input[type="range"]::-ms-fill-lower {
    background-color: #5dc269;
}

input[type="range"]::-ms-fill-upper {
    background-color: #5dc26955;
}

.controller_rangeOther_range2_container {
    height: 3px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    margin-top: 9px;
    padding: 0px 10px;
    /* border: 1px solid red; */
    width: 100%;
}

.controller_rangeOther_range2 {
    height: 3px;
    /* width: 100% !important; */
    background-color: #5dc269;
    transition-duration: 0s;
}

.video_loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000088;
}

.video_loader img {
    object-fit: contain;
    max-width: 60px;
    max-height: 60px;
}

.video_options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #000000aa;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -5px;
}

.video_duration {
    background-color: #00000055;
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 0.7em;
    color: #ffffff;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.video_duration span {
    margin-left: 5px;
    margin-right: 5px;
    letter-spacing: 1px;
}

.video_screen button {
    background-color: #00000055;
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 0.7em;
    color: #ffffff;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid #5dc269;
    cursor: pointer;
}

.video_volume {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 20px 0px;
    padding-right: 10px;
    transform: translateX(150%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video_volume_content {
    background-color: #000000aa;
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    border-radius: 30px;
    position: relative;
}

.video_volume_option {
    font-size: 2em;
}

.video_volume_option button {
    background-color: transparent;
    border-width: 0px;
    color: #5dc269;
}

.volume_cross {
    color: #5dc269;
    padding: 5px 5px;
    /* border: 1px solid red; */
    background-color: #000000aa;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.volume_cross button {
    background-color: transparent;
    color: #5dc269;
    border-width: 0px;
    font-size: 1.5em;
}