.projectsView_Section9 {
    padding: 20px;
    display: flex;
}

.projectsView_Section9_body_project {
    background-color: #ffffff;
    /* box-shadow: 1px 1px 1px 1px #00000022; */
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #00000022;
    width: 100%;
    max-width: 400px;
}

.projectsView_Section9_body_project_title {
    font-size: 1.3em;
    margin-bottom: 10px;
    font-weight: bold;
    color: seagreen;
}

.projectsView_Section9_body_project_date strong {
    color: orange !important;
}

.projectsView_Section9_body_project_description {
    margin-top: 10px;
    font-size: 1.1em;
    color: #000000cc;
}