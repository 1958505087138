html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 250ms;
}

body {
    font-size: 1em;
}

.scaleToHover:hover {
    transform: scale( 1.1);
}

.scaleToHover2:hover {
    transform: scale( 1.2);
}

.scaleToHover3:hover {
    transform: scale( 1.3);
}

div.contact_info {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

div.contact_info_left {
    display: flex;
    flex-wrap: wrap;
    color: #ffffff;
}

div.contact_info_left_phone {
    font-size: 0.9em;
    margin-right: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    transform: scale(0);
    animation: animScale1 250ms forwards;
    margin-bottom: 10px;
}

div.contact_info_left_mail {
    font-size: 0.9em;
    letter-spacing: 1px;
    font-weight: bold;
    transform: scale(0);
    animation: animScale1 250ms forwards;
    animation-delay: 100ms;
}

div.contact_info_right {
    display: flex;
    flex-direction: column;
}

div.contact_info_right_ {
    display: flex;
}

div.contact_info_right_social {
    margin: 0px 20px;
    font-size: 1.3em;
}

a.contact_info_right_social_link {
    color: #ffffff;
}

#google_translate_element {
    margin: 0px !important;
    padding: 0px !important;
    margin-top: 10px !important;
}

.facebookIcon {
    transform: scale(0);
    animation: animScale2 250ms forwards;
    animation-delay: 400ms;
}

.linkedinIcon {
    transform: scale(0);
    animation: animScale2 250ms forwards;
    animation-delay: 500ms;
}

.twitterIcon {
    transform: scale(0);
    animation: animScale2 250ms forwards;
    animation-delay: 600ms;
}

.instagramIcon {
    transform: scale(0);
    animation: animScale2 250ms forwards;
    animation-delay: 700ms;
}

.facebookIcon:hover {
    color: #3b5998;
}

.linkedinIcon:hover {
    color: #0077b5;
}

.twitterIcon:hover {
    color: #00acee;
}

.instagramIcon:hover {
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* Navigation of this site */

.nav {
    height: auto;
    padding: 0px 0px;
    position: relative;
    z-index: 100;
    width: 100% !important;
    background-color: #253526aa;
}

.nav_ {
    box-sizing: border-box;
    border: 0px solid red;
    display: flex;
    width: 100%;
    margin: 0px !important;
    justify-content: space-between;
}

.nav_icon {
    /* flex: 1; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    border: 0px solid blue;
}

.nav_icon_ {
    background-image: url('../../assets/logos/rad-logo.png');
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    width: 65px;
    height: 65px;
    border: 0px solid #ffffff;
    border-radius: 100%;
    transform: scale(0);
    animation: scale_anim3 1s forwards;
    animation-delay: 1.5s;
}

.nav_icon_text {
    margin-left: 5px;
    letter-spacing: 0px;
}

.nav_icon_text_name {
    color: #ffffff;
    font-weight: bold;
    font-size: 1.6em;
}

.nav_icon_text_slug {
    color: #ffffff;
    font-size: 0.68em;
    margin-top: 5px;
}

.nav_menu {
    border: 0px solid green;
}

.nav_menu_cross,
.nav_menuIcon {
    position: absolute;
    opacity: 0;
    z-index: -1000;
    visibility: hidden;
}


/* Content of this site web */

.contain {
    min-height: 100vh;
    border: 0px solid red;
}

div.HomeViewContainer {
    width: 100%;
    min-height: 100%;
    border: 0px solid red;
}

div.don {
    color: #5dc269;
    margin-top: 10px;
    font-size: 0.7em;
    font-weight: bold;
}

div.don span {
    border: 1px solid #5dc269;
    padding: 8px 20px;
    border-radius: 3px;
    letter-spacing: 1px;
    cursor: pointer;
    display: inline-block;
}

div.don span:hover {
    transform: scale(0.9);
}

footer.footer {
    padding: 20px;
    color: #ffffffaa;
    font-size: 0.8em;
    background-color: #000000dd;
    padding-top: 50px;
}

.footer li::before {
    content: "";
}

.footer ul {
    padding: 0px;
    margin: 0px;
}

.footer li {
    margin: 0px;
    padding: 0px;
}

div.footer_text a {
    color: #ffffffaa;
    border-bottom: 1px solid #ffffffaa;
    padding-bottom: 1px;
}

.site-footer__upper .container .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.site-footer__upper .container .row .col-lg-4 {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.footer-widget .footer-widget__title {
    font-size: 1.5em;
    letter-spacing: 1.5px;
    margin-bottom: 15px;
}

.footer-widget__social a {
    color: #ffffff;
    margin-right: 10px;
    cursor: pointer;
    font-size: 2em;
    padding: 5px;
}

.footer-widget__social a:hover {
    /* background-color: #ffffff; */
    color: #5dc269;
    transform: scale(1.1);
}

.footer-widget__social a i {
    cursor: pointer;
}

.footer-widget ul li {
    margin: 10px 0px;
}

.footer-widget ul li a {
    color: #fff;
    font-size: 1.2em;
    letter-spacing: 0px;
    font-weight: normal;
    cursor: pointer;
    line-height: 1.6;
}

.footer-widget ul li a span {
    margin-right: 5px;
}

.footer-contacts p {
    margin: 10px 0px;
    font-size: 1.2em;
    letter-spacing: 0px;
    font-weight: normal;
    color: #fff;
    line-height: 1.6;
}

.footer-contacts p i {
    margin-right: 5px;
}

.footer-contacts p a {
    color: #5dc269;
}

.footer-widget__social img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: block;
    margin-bottom: 15px;
}

.footer-widget__social .nav_icon {
    margin-bottom: 10px;
}

.footer-widget__social .nav_icon_text {
    font-size: 0.7em !important;
}