@keyframes animFromLeft {
    0% {
        transform: translate3d(-1000%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animFromRight {
    0% {
        transform: translate3d(1000%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animFromRight_2 {
    0% {
        margin-left: 100%;
        opacity: 0;
    }
    100% {
        margin-left: 0%;
        opacity: 1;
    }
}

@keyframes animToRight_2 {
    0% {
        margin-left: 0%;
        opacity: 1;
    }
    100% {
        margin-left: 100%;
        opacity: 0;
    }
}

@keyframes animFromTop {
    0% {
        transform: translate3d(0, -1000%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animFromBottom {
    0% {
        transform: translate3d(0, 1000%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animScale1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes animScale2 {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scale_anim3 {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bounceAnimation {
    0% {
        transform: translateY(0px);
    }
    10% {
        transform: translateY(30px);
    }
    20% {
        transform: translateY(-20px);
    }
    30% {
        transform: translateY(30px);
    }
    40% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(30px);
    }
    60% {
        transform: translateY(-20px);
    }
    70% {
        transform: translateY(30px);
    }
    80% {
        transform: translateY(-20px);
    }
    90% {
        transform: translateY(30px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes opacity_anim {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animeOp1 {
    from {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animeCardRotate {
    0% {
        transform: rotateZ(0deg);
    }
    25% {
        transform: rotateZ(-15deg);
    }
    50% {
        transform: rotateZ(15deg);
    }
    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes animeScaleX {
    from {
        opacity: 0;
        transform: scaleX(0);
    }
    to {
        opacity: 1;
        transform: scaleY(1);
    }
}

@keyframes animeScaleY {
    from {
        opacity: 0;
        transform: scaleY(0);
    }
    to {
        opacity: 1;
        transform: scaleY(1);
    }
}

@keyframes animeBorderTop {
    from {
        opacity: 1;
        transform: translateX(-100%);
    }
    to {
        opacity: 0;
        transform: translateX(0%);
    }
}

@keyframes animeBorderRight {
    from {
        opacity: 1;
        transform: translateY(-150%);
    }
    to {
        opacity: 0;
        transform: translateY(0%);
    }
}

@keyframes animeBorderBottom {
    from {
        opacity: 1;
        transform: translateX(100%);
    }
    to {
        opacity: 0;
        transform: translateX(0%);
    }
}

@keyframes animeBorderLeft {
    from {
        opacity: 1;
        transform: translateY(150%);
    }
    to {
        opacity: 0;
        transform: translateY(0%);
    }
}

@keyframes animeHeight1 {
    from {
        height: 0%;
    }
    to {
        height: 100%;
    }
}

@keyframes animeHeight2 {
    from {
        height: 100%;
    }
    to {
        height: 0%;
    }
}