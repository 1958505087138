@media (max-width: 680px) {
    div.OnCompetenceView_top_title span {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    div.OnCompetenceView_top_title span {
        font-size: 1.2em;
    }
}