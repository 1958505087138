div.login_ground {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

div.login_ground_ {
    display: flex;
    width: 100%;
    height: 90%;
    justify-content: space-between;
    max-width: 1000px;
    margin: auto;
    border: 0px solid red;
    transform: translate3d(0, 0, 0);
}

div.login_ground_left {
    padding-left: 40px;
}

div.login_ground_left svg {}

div.login_ground_right {
    bottom: 0;
    right: 0;
    position: absolute;
    margin-right: 0px;
    margin-bottom: 0px;
    border: 0px solid red;
}

div.login_formContainer {
    width: 100%;
    max-width: 400px;
    margin: auto;
    border: 0px solid red;
    padding: 0px 20px;
    padding-top: 13px;
    position: relative;
    z-index: 100000;
}

div.login_form_logo svg {
    margin: auto;
    display: block;
    opacity: 0;
    animation: opacity_anim 500ms forwards;
    animation-delay: 500ms;
}

div.login_form {
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 20px 40px;
    margin-top: 20px;
    transform: translate3d(0, 0, 0);
    transform: scaleY(0);
    opacity: 0;
    transform-origin: top;
    margin-bottom: 80px;
    animation: animeScaleY 600ms forwards;
}

div.login_form_fm_ {
    margin-bottom: 20px;
}

div.login_form_title {
    text-align: center;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 1.6em;
    margin-top: 20px;
    margin-bottom: 30px;
    opacity: 0;
    animation: opacity_anim 500ms forwards;
    animation-delay: 1000ms;
}

div.login_form_fm_label {
    padding-left: 5px;
    font-weight: 500;
    font-size: 0.9em;
}

input.login_form_fm_input {
    background: #FFFFFF;
    border: 1px solid #B5B5B5;
    box-sizing: border-box;
    border-radius: 7px;
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    outline: none;
}

div.login_form_fm_inputContainer {
    transform: translate3d(0, 0, 0);
    overflow: hidden;
}


/* Email input */

.inputLabel {
    opacity: 0;
    animation: opacity_anim 500ms forwards;
    animation-delay: 1100ms;
}

.login_form_fm_input {
    opacity: 0;
    animation: opacity_anim 500ms forwards;
    animation-delay: 1000ms;
}


/* -------------------------------------- */

input.login_form_fm_submit_ {
    color: #ffffff;
    border-radius: 3px;
    padding: 15px 10px;
    width: 100%;
    font-weight: bold;
    font-size: 1em;
    margin-top: 15px;
    outline: nones;
    opacity: 0;
    animation: opacity_anim 500ms forwards;
    animation-delay: 1300ms;
    border: 0px solid #ffffff00;
    cursor: pointer;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
}

input.login_form_fm_submit_:hover {
    transform: scale(0.9);
    letter-spacing: 1px;
    font-size: 1em;
}

div.login_forgotPass {
    font-size: 0.8em;
    text-align: right;
    margin-top: 10px;
    font-weight: 500;
    opacity: 0;
    animation: opacity_anim 500ms forwards;
    animation-delay: 1400ms;
}

div.login_forgotPass span {
    border-bottom: 1px solid #7A57A0;
}

div.login_footer {
    height: 60px;
    background: rgba(24, 8, 71, 0.19);
    margin-left: -40px;
    margin-right: -40px;
    margin-bottom: -20px;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    font-weight: 500;
}

div.login_footer span {
    border-bottom: 1px solid #7A57A0;
    opacity: 0;
    animation: opacity_anim 500ms forwards;
    animation-delay: 1500ms;
}