div.dashboardGeneral {
    padding-bottom: 40px;
}

div.dashboardGeneral_top {
    margin-top: 20px;
    font-size: 1.5em;
    letter-spacing: 1px;
}

div.dashboardGeneral_content_top {
    margin-bottom: 30px;
}

span.dashboardGeneral_content_add {}

div.dashboardGeneral_content {
    margin-top: 40px;
}

div.dashboardGeneral_content_ {
    margin-bottom: 70px;
}

div.dashboardGeneral_content_ span {
    color: #00000055;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 1px;
}

ul.dashboardGeneral_content_list {
    margin-top: 10px;
}

div.dashboardGeneral_content_list_eltName {
    color: #000000aa;
}

div.dashboardGeneral_content_list_elt {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

span.update {
    background-color: #5dc269;
    margin-left: 10px;
    color: #ffffff !important;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 0.8em !important;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

span.delete {
    background-color: #ff0000;
    margin-left: 10px;
    color: #ffffff !important;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 0.8em !important;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

span.update_2 {
    color: #5dc269 !important;
    border: 1px solid #5dc269;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 0.8em !important;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

span.delete_2 {
    color: #ff0000 !important;
    border: 1px solid #ff0000;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 0.8em !important;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

input.dashboardGeneral_content_list_input {
    width: 100%;
    max-width: 200px;
    margin-top: 10px;
    padding: 5px 10px;
    border: 1px solid #5dc269;
    border-radius: 5px;
    box-shadow: 5px 5px 10px 2px #00000000;
    outline: none;
}

div.dashboardGeneral_content_list_inputContainer {
    position: absolute;
    z-index: -1000;
    opacity: 0;
    transform: scaleY(0);
    margin-bottom: 30px;
    width: 100%;
}

.loading {
    z-index: 100000;
}

.dashboardGeneral_content_list_eltName i {
    font-size: 1.2em;
    margin-left: 10px;
}

.updateSocial_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000055;
}

.updateSocial_container_ {
    margin: auto;
    border: 0px solid red;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
}

.updateSocial_input {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.updateSocial_input_name {
    font-size: 0.9em;
}

.updateSocial_input_balise {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #00000055;
    border-radius: 5px;
    outline: none;
}

.updateSocial_input_selected {
    display: flex;
    align-items: center;
}

.updateSocial_input_selected span {
    width: 50px;
    height: 40px;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #00000055;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5dc269;
    font-size: 1.3em;
}

.updateSocial_input_selected select {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #00000055;
    border-radius: 5px;
    outline: none;
    width: 100%;
    background-color: transparent;
}

div.updateSocial_inputSubmit_container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    display: flex;
}

.updateSocial_inputSubmit,
.updateSocial_inputBack {
    background-color: #5dc269;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.8em;
    letter-spacing: 1px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    margin-right: 20px;
}

.updateSocial_inputBack {
    background-color: red;
    margin-left: 20px;
    margin-right: 0px;
}