div.dashboardRubrique {}

div.dashboardRubrique_top {
    font-size: 1.5em;
    letter-spacing: 1px;
    margin-top: 20px;
}

div.dashboardRubrique_content_ {
    margin-top: 20px;
}

div.dashboardRubrique_content_ span {
    color: #5dc269;
    font-size: 0.9em;
    font-weight: bold;
    letter-spacing: 1px;
    border: 1px solid #5dc269;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
}

div.dashboardRubrique_content_ span:hover {
    background-color: #5dc269;
    transform: scale(1.1);
    color: #ffffff;
}

div.dashboardLeft_arrow {
    color: #5dc269;
    font-size: 3em;
    font-weight: bold;
    border: 0px solid red;
}

.dashboardLeft_arrow_svg {
    cursor: pointer;
}