a {
    text-decoration: none;
}

ul {
    list-style: none;
}

ul#menu {
    list-style: none;
    width: 100%;
    border: 0px solid red;
    user-select: none;
    opacity: 0;
    animation: opacity_anim 1000ms forwards;
    animation-delay: 2s;
}

ul.menu {
    display: flex;
    padding: 0px 40px;
    padding-right: 20px;
    justify-content: space-between;
}

li.menu_item {
    color: #ffffff;
    margin: 0px 20px;
    padding: 10px 0px;
    transform: translate3d(0, 0, 0);
    white-space: nowrap;
    /* transform: scale(0); */
}

li.menu_item::before {
    font-family: FontAwesome;
    content: "" !important;
}


/* .accueil {
    animation: animScale2 250ms forwards;
    animation-delay: 2s;
}

.about-us {
    animation: animScale2 250ms forwards;
    animation-delay: 2250ms;
}

.une {
    animation: animScale2 250ms forwards;
    animation-delay: 2500ms;
}

.project {
    animation: animScale2 250ms forwards;
    animation-delay: 2750ms;
}

.contactUs {
    animation: animScale2 250ms forwards;
    animation-delay: 3000ms;
}

.media {
    animation: animScale2 250ms forwards;
    animation-delay: 3250ms;
}

.plus {
    animation: animScale2 250ms forwards;
    animation-delay: 3500ms;
} */

span.menu_item_title {
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 0.6em;
    display: flex;
    align-items: center;
}

ul.menu_item_subMenu {
    background-color: #253526;
    padding: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    position: absolute;
    transform-origin: 0 0;
    right: 0;
    color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: scaleY(0);
    transform-origin: 0 0;
    z-index: 10;
}

.menu_item_subMenu_text_ {
    border-bottom: 1px solid #ffffff66;
    padding-bottom: 25px;
    color: #ffffff !important;
}

.menu_item_subMenu_text {
    font-weight: normal;
    font-size: 0.8em;
    margin-bottom: 20px;
    cursor: pointer;
    color: #ffffff !important;
}

li.menu_item_subMenu_text::before,
li.menu_item_subMenu_text_::before {
    font-family: FontAwesome;
    content: "" !important;
}

.menu_item:hover ul {
    transform: scaleY(1);
}

.menuIcon {
    font-size: 1.5em !important;
    margin-right: 5px;
}

.menuIcon2 {
    font-size: 1.8em !important;
}

.menuItemUnderLine {
    background-color: #ffffff;
    height: 3px;
    margin-top: 20px;
}

.menu_item_title:hover+div.menuItemUnderLine {
    opacity: 1 !important;
    width: 100% !important;
}