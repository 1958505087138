html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 250ms;
}

body {
    font-size: 1em;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #5dc26900;
    border: 0px solid #5dc26955;
    border-radius: 0px;
    position: fixed;
    left: 0;
}

body::-webkit-scrollbar-track {
    width: 0px;
    background-color: #5dc26900;
    border: 0px solid transparent;
}

body::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 30px;
    background-color: #5dc269;
    border: 0px solid transparent;
}

.scrollVBr::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #5dc26900;
    border: 0px solid #5dc26955;
    border-radius: 0px;
    position: fixed;
    left: 0;
}

.scrollVBr::-webkit-scrollbar-track {
    width: 0px;
    background-color: #5dc26900;
    border: 0px solid transparent;
}

.scrollVBr::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 30px;
    background-color: #5dc269;
    border: 0px solid transparent;
}

.transition {
    transition: all 500ms;
}

.background_primary {
    background-color: #253526;
}

.background_primary2 {
    background-color: #5dc269;
}

.background_secondary {
    background-color: #272727;
}

.color_primary {
    color: #253526;
}

.color_primary2 {
    color: #5dc269;
}

.color_secondary {
    color: #272727;
}

.fixed {
    position: fixed !important;
    top: 0;
    width: 100% !important;
    margin: 0;
    z-index: 10000000;
}

.underline {
    position: relative;
    width: 30px;
    height: 3px;
    background-color: #5dc269;
    left: 0;
    display: block;
    margin-top: 10px;
}

.title {
    font-weight: bold;
    color: #5dc269;
}

.textForm {
    word-wrap: break-word;
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    letter-spacing: 0px;
    white-space: wrap;
}

.text-sizes-1 {
    font-size: 1em;
    line-height: 1.9em;
}

.lineHeight1 {
    line-height: 1.6em;
}

.inlineText {
    white-space: nowrap;
}

strong {
    color: #5dc269 !important;
    font-weight: bold;
}

.titleStyle1 {
    font-weight: bold;
    font-size: 1.9em;
    margin-bottom: 60px;
    text-align: left;
    padding-left: 20px;
    margin-top: 30px;
}

.titleStyle2 {
    font-size: 1.9em;
    margin-top: 0px;
    margin-bottom: 20px;
}

.titleStyle4 {
    font-size: 3.7em;
    margin-top: 0px;
    margin-bottom: 20px;
}

.titleStyle3 {
    font-size: 3.7em;
}

.card_title {
    font-weight: bold;
    font-size: 1.1em;
    color: #5dc269;
    display: flex;
    align-items: flex-start;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    margin: 25px 0px;
    font-size: 0.9em;
    font-family: sans-serif;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

table table {
    /* box-shadow: 0 0 0px rgba(0, 0, 0, 0); */
}

table thead th {
    background-color: #5dc26900;
    color: #5dc269;
    border: 1px solid #5dc269;
}

table th,
table td {
    padding: 20px 5px;
    box-sizing: border-box;
    border-left: 1px solid #ffffff66;
    border-bottom: 1px solid #ffffff66;
    background-color: #5dc26900;
    color: #5dc269;
    border: 1px solid #5dc269;
}

.link_menu {
    color: #ffffff;
}

.left-border {
    border-left: 1px solid #5dc269;
    padding-left: 20px;
}

ul {
    list-style: none;
    padding-left: 20px;
}

li {
    list-style: none;
    color: #5dc269;
}

li::before {
    font-family: FontAwesome;
    content: "\f00C";
    margin-right: 10px;
    color: #5dc269;
}

@media( max-width: 1090px) {
    .titleStyle2 {
        font-size: 1.3em;
    }
    .titleStyle3 {
        font-size: 2.5em;
    }
    .card_title {
        font-size: 1.1em;
    }
    .titleStyle4 {
        font-size: 2.5em;
    }
}

@media( max-width: 700px) {
    .titleStyle4 {
        font-size: 2em;
    }
}

@media( max-width: 600px) {
    .titleStyle4 {
        font-size: 1.7em;
    }
}

@media( max-width: 450px) {
    .titleStyle2 {
        font-size: 1.1em;
    }
    .titleStyle3 {
        font-size: 2.3em;
    }
    .titleStyle1 {
        font-size: 1.6em;
    }
    .titleStyle4 {
        font-size: 1.4em;
    }
}

@media( max-width: 400px) {
    .titleStyle4 {
        font-size: 1.2em;
    }
}

@media( max-width: 360px) {
    .titleStyle2 {
        font-size: 1em;
    }
    .titleStyle3 {
        font-size: 2em;
    }
    .titleStyle4 {
        font-size: 1em;
    }
}

@media( max-width: 320px) {
    .titleStyle1 {
        font-size: 1.4em;
    }
}

@media( max-width: 305px) {
    .titleStyle4 {
        font-size: 0.9em;
    }
}

.goog-logo-link {
    opacity: 0 !important;
    position: absolute !important;
    transform: scale(0) !important;
    top: -100000 !important;
    left: -1000000 !important;
}

.skiptranslate {
    font-size: 0 !important;
}

.goog-te-combo {
    width: 100%;
    padding: 8px 20px;
    border-radius: 5px;
    border: 1px solid #5dc269;
    background-color: #5dc26900;
    color: #5dc269;
    outline: none;
    display: inline-block;
}

.goog-te-combo:hover {
    transform: scale(0.9);
}

.goog-te-combo:hover {}

div.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.loading img {
    width: 50px;
}

.gmnoprint {
    opacity: 0 !important;
    transform: scale(0) !important;
    margin-left: -1000000000% !important;
    position: relative !important;
    font-size: 0px !important;
}

.gm-style-cc {
    opacity: 0 !important;
    transform: scale(0) !important;
    margin-left: -1000000000% !important;
    position: relative !important;
    font-size: 0px !important;
}

.table1 {}

.table1_tr {}

.table1_trth {
    background-color: #00000011;
}

.table1_th {
    padding: 15px;
    text-align: left;
    border: 0px solid #00000000;
}

.table1_th_ {
    width: 100%;
}

.table1_th_center {
    text-align: center;
}

.table1_th_Add {
    padding: 10px;
    cursor: pointer;
}

.colorRed {
    color: red;
}

.progress {
    width: 100%;
    border: 1px solid #5dc269;
    height: 10px;
    border-radius: 100px !important;
    display: flex;
    align-items: center;
}

.progressBar {
    background-color: #5dc269;
    height: 8px;
    border-radius: 100px !important;
}

.progressText {
    color: #5dc269;
    text-align: center;
    font-weight: bold;
}

.progressMessage {
    color: #5dc269;
    text-align: center;
    margin-top: 20px;
}

.progressMessage::after {
    font-family: FontAwesome;
    content: "\f00C";
    margin-left: 10px;
    color: #5dc269;
}

.tabContainer_name {
    padding: 0px 20px;
    font-size: 1.2em;
    letter-spacing: 2px;
    text-align: center;
    border: 0px solid red;
    margin-bottom: 20px;
}

.bottomLine_green {
    width: 100%;
    margin: 20px 0px;
    background-color: #5dc269;
    height: 1px;
}

.tabContainer_ table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 0.9em;
    font-family: sans-serif;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
    border: 0px solid #5dc269 !important;
}

.tabContainer_ table strong {
    padding: 0px 20px;
    font-size: 1.2em;
    letter-spacing: 1px;
    text-align: center;
    border: 0px solid red;
}

.tabContainer_ th {
    /* background-color: #253526aa; */
    border: 0px solid #5dc269 !important;
}

.tabContainer_ th,
.tabContainer_ td {
    padding: 15px 5px !important;
    box-sizing: border-box;
    border-left: 1px solid #ffffff66;
    /* background-color: #253526aa; */
    border: 0px solid #5dc269 !important;
}

.tabContainer_ table tr:first-child {
    /* Styles pour la première balise tr */
    font-weight: bold;
    font-size: 1.2em;
}

.tabContainer_ td {
    color: #000000aa !important;
}

.tabContainer_ img {
    width: 40px;
    display: inline;
}

.tabContainer_ .tb_ {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.radSections_title_label {
    padding-left: 10px;
    margin-bottom: -10px;
    margin-top: 20px;
}

.radSections_title_content {
    border-bottom: 1px solid #5dc269;
    padding-bottom: 40px;
}

.dashboardRubrique_container {
    overflow: hidden;
}

.dashboardRubrique_container_ {
    margin-left: 100%;
    opacity: 0;
    animation: animFromRight_2 500ms forwards;
}

div.borderTop {
    position: absolute;
    width: 100%;
    height: 3px;
    top: 0;
    margin-top: 5px;
    transform: translateX(-100%);
    opacity: 1;
    animation: animeBorderTop 500ms forwards;
}

div.borderRight {
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0;
    right: 0;
    margin-top: 0px;
    margin-right: 0px;
    opacity: 1;
    transform: translateY(-100%);
    animation: animeBorderRight 500ms forwards;
    animation-delay: 300ms;
}

div.borderLeft {
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0;
    left: 0;
    margin-top: 0px;
    transform: translateY(100%);
    animation: animeBorderLeft 500ms forwards;
    animation-delay: 900ms;
}

div.borderBottom {
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    margin-top: 5px;
    transform: translateX(100%);
    animation: animeBorderBottom 500ms forwards;
    animation-delay: 600ms;
}

.content {
    margin-top: 50px;
    word-wrap: break-word;
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    letter-spacing: 0px;
    white-space: wrap;
    font-size: 1em;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
    font-weight: bold;
    letter-spacing: 1px;
    /* font-size: 1.5em; */
    border-bottom: 1px solid #5dc269;
    padding-bottom: 10px;
    font-family: Lato-Black;
    margin-top: 20px;
    margin-bottom: 20px;
    /* display: inline-block; */
}

.content p {
    margin-top: 0px;
    padding-top: 10px;
    color: #00000099;
    border-left: 1px solid #5dc269;
    padding-left: 20px;
    font-size: 1em;
    line-height: 1.9em;
}

.content ul,
.content ol {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 10px;
    color: #00000099;
    border-left: 1px solid #5dc269;
    padding-left: 40px;
    font-size: 1em;
    line-height: 1.9em;
}

.content ul li,
.content ol li {
    margin-top: 0px;
    padding-top: 5px;
}

.button-container span {
    margin-right: 5px;
    margin-left: 5px;
    width: 100%;
    text-align: center;
    padding: 15px 10px;
}

.project-content-container {
    overflow: hidden;
}

.back2 {
    background-color: #5dc269;
    color: #ffffff;
    display: inline-flex;
    margin-left: 10px;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.back2- {
    font-size: 1em;
    width: 30px;
    height: 30px;
}

.no-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 3em;
    color: #296830;
    font-weight: bold;
    letter-spacing: 1px;
    word-wrap: break-word;
}

#google_translate_element span {
    display: none;
}